import { Button } from "@mui/material";

const FeedbackButton = () => {
  const openForm = () => {
    const formUrl =
      "https://forms.office.com/Pages/ResponsePage.aspx?id=ZjhWcdHzIU2QdQic1OYbO26YG1oRVGVBt1rGenb8CUVUOTdZMk1aUU9QR1ZNRzU3V0RRWVgzUFdLMi4u";
    window.open(formUrl, "_blank");
  };

  return (
    <Button onClick={openForm} variant={"filled"}>
      Feedback
    </Button>
  );
};

export default FeedbackButton;
