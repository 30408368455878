// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { compile } from "mathjs";

export interface Referenced {
  reference: string;
  displayName: string;
  yTitle?: string;
}

export interface TagUnit {
  name: string;
  equipment: TagEquipment[];
}

export interface TagEquipment {
  name: string;
  tags: (Tag | DerivedTag)[];
}

export interface Tag extends Referenced {
  id: number;
  call: (input: number) => number;
}

export interface TagCalculation {
  (input: Record<string, number>): number;
}

export interface DerivedTag extends Referenced {
  inputs: Referenced[];
  call: TagCalculation;
}

export function newTag(id: number, ref: string, displayName: string, expression?: string): Tag {
  return { id, reference: ref, displayName, call: tagUpdateFunc(expression) };
}

function tagUpdateFunc(expression?: string): (input: number) => number {
  if (!expression) {
    return (input: number): number => {
      return input;
    };
  }
  const func = compile(expression);
  return (input: number): number => {
    return func.evaluate({ x: input });
  };
}

export function newDerivedTag(
  inputs: Referenced[],
  ref: string,
  displayName: string,
  expression: string
): DerivedTag {
  const call = createTagCalculation(expression);
  return { inputs, reference: ref, displayName, call };
}

function createTagCalculation(expression: string): TagCalculation {
  const func = compile(expression);
  return (input: Record<string, number>): number => {
    return func.evaluate(input);
  };
}
