import React, { useState, useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  Button,
  IconButton,
  Paper,
  TableContainer,
  TablePagination,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";

const CreateTableAbstract = ({ passedData, headers, type }) => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState("dateTime");
  const [order, setOrder] = useState("desc");
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [data, setData] = useState(passedData);
  const [isEditing, setIsEditing] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const showDelete = type === "Lab";

  const excludedHeaders = useMemo(
    () => ["ID", "id", "collectDT", "lowerLimit", "upperLimit", "CollectDT"],
    []
  );
  const filteredHeaders = useMemo(
    () => headers.filter((header) => !excludedHeaders.includes(header)),
    [headers, excludedHeaders]
  );

  useEffect(() => {
    setData(passedData);
  }, [passedData]);

  const handleRequestSort = useCallback(
    (property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    },
    [order, orderBy]
  );

  const toggleColumnVisibility = useCallback((header) => {
    setHiddenColumns((prevHiddenColumns) =>
      prevHiddenColumns.includes(header)
        ? prevHiddenColumns.filter((column) => column !== header)
        : [...prevHiddenColumns, header]
    );
  }, []);

  const handleCellChange = useCallback(
    (event, rowIndex, header) => {
      const updatedData = [...data];
      updatedData[rowIndex][header] = event.target.value;
      setData(updatedData);
    },
    [data]
  );

  const handleSaveRow = useCallback(
    async (rowIndex) => {
      setIsEditing(false);
      try {
        const updatedRow = data[rowIndex];
        await axios.put(`${apiUrl}/Samples/${updatedRow.id}`, updatedRow);
      } catch (error) {
        console.error("Error saving data:", error);
      }
    },
    [data, apiUrl]
  );

  const handleDeleteRow = useCallback(
    async (rowIndex) => {
      const shouldDelete = window.confirm("Are you sure you want to delete this row?");
      if (shouldDelete) {
        try {
          const rowToDelete = data[rowIndex];
          await axios.delete(`${apiUrl}/Samples/${rowToDelete.id}`);
          setData((prevData) => prevData.filter((_, idx) => idx !== rowIndex));
        } catch (error) {
          console.error("Error deleting data:", error);
        }
      }
    },
    [data, apiUrl]
  );

  const handleEditRow = useCallback(() => {
    setIsEditing(true);
  }, []);

  const sortedData = useMemo(() => {
    return data.sort((a, b) => {
      if (order === "asc") {
        return a[orderBy] < b[orderBy] ? -1 : 1;
      } else {
        return a[orderBy] > b[orderBy] ? -1 : 1;
      }
    });
  }, [data, orderBy, order]);

  const emptyRows = useMemo(
    () => rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage),
    [rowsPerPage, data.length, page]
  );

  return (
    <>
      <div>
        <TableContainer component={Paper} className="narrow-table">
          <Table aria-label="simple table">
            {hiddenColumns.length > 0 && (
              <div style={{ marginTop: "10px" }}>
                <Button variant="contained" color="primary" onClick={() => setHiddenColumns([])}>
                  Unhide Columns
                </Button>
              </div>
            )}
            <TableBody>
              <TableRow>
                {filteredHeaders.map(
                  (header, index) =>
                    !hiddenColumns.includes(header) && (
                      <TableCell key={index} align="center">
                        <TableSortLabel
                          active={orderBy === header}
                          direction={orderBy === header ? order : "asc"}
                          onClick={() => handleRequestSort(header)}
                        >
                          {header}
                        </TableSortLabel>
                        <IconButton size="small" onClick={() => toggleColumnVisibility(header)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    )
                )}
                {isEditing && (
                  <TableCell align="center" className="bold-header">
                    Actions
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
            <TableBody>
              {(rowsPerPage > 0
                ? sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : sortedData
              ).map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {filteredHeaders.map(
                    (header, colIndex) =>
                      !hiddenColumns.includes(header) && (
                        <TableCell key={colIndex} align="center">
                          <input
                            type="text"
                            value={row[header]}
                            onChange={(event) => handleCellChange(event, rowIndex, header)}
                            readOnly={!isEditing}
                          />
                        </TableCell>
                      )
                  )}
                  {showDelete &&
                    (isEditing ? (
                      <TableCell align="center">
                        <Button onClick={() => handleSaveRow(rowIndex + rowsPerPage * page)}>
                          Save
                        </Button>
                      </TableCell>
                    ) : (
                      <TableCell align="center">
                        <IconButton
                          onClick={() => handleDeleteRow(rowIndex + rowsPerPage * page)}
                          color="secondary"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    ))}
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={filteredHeaders.length + 1} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
          }}
        />
        <br />
        {showDelete && !isEditing && <Button onClick={handleEditRow}>Edit</Button>}
      </div>
    </>
  );
};

CreateTableAbstract.propTypes = {
  passedData: PropTypes.arrayOf(
    PropTypes.shape({
      DateTime: PropTypes.string,
      TagName: PropTypes.string,
      Value: PropTypes.number,
      KeyID: PropTypes.number,
      FacilityID: PropTypes.number,
      EquipmentID: PropTypes.number,
      EquipmentName: PropTypes.string,
      id: PropTypes.number,
      sampleLocation: PropTypes.string,
      analyte: PropTypes.string,
      collectDT: PropTypes.string,
      sampleTestDT: PropTypes.string,
      uom: PropTypes.string,
      resultValue: PropTypes.number,
      upperLimit: PropTypes.number,
      lowerLimit: PropTypes.number,
    })
  ).isRequired,
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  type: PropTypes.string,
};

export default CreateTableAbstract;
