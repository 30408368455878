import { LabSheetPage } from "./LabSheetPage";
import { Datum, UseOpcDataOptions } from "../../handlers/opcData";
import { AnalyteHourGrouping } from "../../hooks/useFilteredSampleStream";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const sampleLocations = ["White Sugar", "White Sugar #1"];

const analytes = [
  "Color",
  "% Moisture",
  "% Ash",
  "White Sediment",
  "Black Sediment",
  "V Speck",
  "SO2",
  "MA",
  "CV",
  "Screen +35",
  "Screen +65",
  "Screen -100",
];

const rows: [string, string][] = [
  ["CWT Produced", "100lb bags"],
  ["White Sugar", "Color"],
  ["White Sugar", "% Moisture"],
  ["White Sugar", "% Ash"],
  ["White Sugar", "White Sediment"],
  ["White Sugar", "Black Sediment"],
  ["White Sugar", "V Speck"],
  ["White Sugar", "SO2"],
  ["White Sugar", "MA"],
  ["White Sugar", "CV"],
  ["White Sugar", "Screen +35"],
  ["White Sugar", "Screen +65"],
  ["White Sugar", "Screen -100"],
];

const opcData: UseOpcDataOptions<Record<string, AnalyteHourGrouping>> = {
  reducer(data) {
    const hourMinMax = data.reduce(addDatum, {});
    return { "CWT Produced.100lb bags": toAnalyteHourGrouping(hourMinMax) };
  },
  tags: [36064],
  name: "CWT Produced",
};

interface HourMinMax {
  [hour: string]: {
    min: number;
    max: number;
  };
}

function toAnalyteHourGrouping(hourMinMax: HourMinMax): AnalyteHourGrouping {
  // manually set the operating window here
  return {
    sampleLocation: "CWT Produced",
    analyte: "100lb bags",
    values: toValues(hourMinMax),
    operatingWindow: {
      min: 395,
      max: 500,
    },
  };
}

function toValues(hourMinMax: HourMinMax): Record<string, number[]> {
  return Object.entries(hourMinMax).reduce((acc, [hour, { min, max }]) => {
    return { ...acc, [hour]: [max - min] };
  }, {});
}

function addDatum(acc: HourMinMax, { time, value }: Datum): HourMinMax {
  const dateHour = dayjs.utc(time.toISOString()).local().format("YYYY-MM-DDTHH");
  const existing = acc[dateHour];
  if (!existing) {
    return {
      ...acc,
      [dateHour]: {
        min: value as number,
        max: value as number,
      },
    };
  }
  return {
    ...acc,
    [dateHour]: {
      min: Math.min(existing.min, value as number),
      max: Math.max(existing.max, value as number),
    },
  };
}

export const ProductionSugarPage = () => {
  return (
    <LabSheetPage
      sampleLocations={sampleLocations}
      analytes={analytes}
      rows={rows}
      opcData={opcData}
    />
  );
};
