import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { FieldValues, Path, UseFormRegister } from "react-hook-form";

type FormSelectFieldProps<T extends FieldValues> = {
  variant: "standard" | "filled" | "outlined";
  label: string;
  options: string[];
  error?: boolean;
  required?: boolean;
  helperText?: string;
  register: UseFormRegister<T>
  name: Path<T>;
}

const FormSelectField = <T extends FieldValues, >(
  {
    variant,
    label,
    options,
    error,
    helperText,
    register,
    required,
    name,
  }: FormSelectFieldProps<T>) => {
  return (
    // make FormControl inherit style from enclosing component
    <TextField
      variant={variant}
      id={label}
      required={required}
      label={`${label}`}
      select={true}
      error={error}
      helperText={helperText}
      {...register(name)}
    >
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}

    </TextField>
  );
};

export default FormSelectField;