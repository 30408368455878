import React, { useEffect, useRef } from "react";
import "./css/animate.min.css";
import "./css/icons/icons.css";
import "./style.css";
import { Nav, Navbar, Dropdown, DropdownButton, Button } from "react-bootstrap";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { loginRequest, b2cPolicies } from "../../components/MSAL/authConfig";
import LandingPageNavBar from "./LandingPageNavBar";
//import * as ReactBootstrap from "react-bootstrap";
//import "./js/jquery.sinusoid.js";
export const LandingPage = () => {
  const trianglesRef = useRef(null);

  useEffect(() => {
    const calculateTriangleStyles = () => {
      if (trianglesRef.current) {
        const tw = trianglesRef.current.offsetWidth / 7 + 32;
        const ta = tw / 2 + 22;

        trianglesRef.current.querySelector(
          ".down-triangle"
        ).style.borderTop = `${tw}px solid rgba(0,0,0,0.8)`;
        trianglesRef.current.querySelector(
          ".down-triangle"
        ).style.borderLeft = `${ta}px solid transparent`;
        trianglesRef.current.querySelector(
          ".down-triangle"
        ).style.borderRight = `${ta}px solid transparent`;

        trianglesRef.current.querySelector(
          ".up-triangle"
        ).style.borderBottom = `${tw}px solid rgba(0,0,0,0.8)`;
        trianglesRef.current.querySelector(
          ".up-triangle"
        ).style.borderLeft = `${ta}px solid transparent`;
        trianglesRef.current.querySelector(
          ".up-triangle"
        ).style.borderRight = `${ta}px solid transparent`;
      }
    };

    // Call the calculation function on initial render and when the window is resized.
    calculateTriangleStyles();

    window.addEventListener("resize", calculateTriangleStyles);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", calculateTriangleStyles);
    };
  }, []);

  const handleLinkClick = (e) => {
    e.preventDefault();
    const targetId = e.target.getAttribute("href").substring(1);
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop,
        behavior: "smooth",
      });
    }

    ///////////////////////////////////////////////////////////////////////////////////////////
    const { instance, inProgress } = useMsal();
    let activeAccount;

    if (instance) {
      activeAccount = instance.getActiveAccount();
    }

    const handleLoginRedirect = () => {
      instance.loginRedirect(loginRequest).catch((error) => console.log(error));
    };

    const handleLogoutRedirect = () => {
      instance.logoutRedirect();
    };

    const handleProfileEdit = () => {
      if (inProgress === InteractionStatus.None) {
        instance.acquireTokenRedirect(b2cPolicies.authorities.editProfile);
      }
    };
  };
  return (
    <>
      <LandingPageNavBar />
      {/*<!-- START  jumbotron for a primary marketing message or call to action -->*/}
      <div className="jumbotron jumbotron-fluid" id="home">
        <div className="intro">
          <div className="container">
            <div className="row">
              <h1>VIRTUAL FACTORY</h1>
              <h5 className="text-center">
                The platform for full process digitization and automation to Factory 5.0 and beyond.
              </h5>
            </div>
          </div>
        </div>
      </div>
      {/*<!-- END  jumbotron for a primary marketing message or call to action -->*/}
      {/*<section id="about">*/}
      {/*  <div className="container">*/}
      {/*    <div className="row">*/}
      {/*      <p className="text-center">*/}
      {/*        A platform for <b>Process Controls</b>, Modeling, Optimization, Data Storage*/}
      {/*        (historian), Process Analytics through AI. Fueling the advancement of manufacturing*/}
      {/*        facilities and product development.<br></br>*/}
      {/*      </p>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</section>*/}
      {/* START services section content */}
      <section id="services">
        <div className="container">
          {/*<!-- Example row of columns -->*/}
          <div className="row features">
            <div className="col-sm-12 text-center">
              <h2>Services</h2>
              <p>
                We are experts in process digitization including Modeling, Simulation, Data
                Analytics, and Automation. We support clients in multiple industries to contain
                their problems, and ship working solutions.
              </p>
              <h3>Simulation Development and Design</h3>
              <p>
                Our ability to understand product design and development as a specialist in research
                and development coupled with a background in modeling and software development make
                it ideal to take a idea and go from concept to reality.
              </p>

              <h3>Analytics for Engineering</h3>
              <p>
                Supporting engineering for sustainable process improvements product design and
                development as a integrating disconnected data sources and apply cutting edge
                analytics and deep learning.
              </p>

              <h3>Automation</h3>
              <p>
                Engineering workloads are more intricate, and analysis intensive than ever. There is
                simply a better way to work. Our clients work directly with us to develop a workload
                analysis. Then the engineers workload is developed into a set of applications. These
                assist the engineer as a supplemental resource.{" "}
              </p>
              <h3 className="text-center">Simulation-Virtual Twin Development</h3>
              <p>
                At VESL we are able to provide new insights to your process to reduce waste. These
                can test equipment which is not accessible due to physical constraints. CFD and
                Multiphysics are in our wheelhouse, dont need to go that far, then why not generate
                automated engineering analysis.
              </p>
              <p>
                What will your success case be? Contact us and we will work with you to develop your
                solution to save time and resources.
              </p>
              <img
                src="/images/virtualtwin_tenter.png"
                alt={"Virtual Twin of Physical Equipment"}
              />
              <h3 className="text-center">Data Development</h3>
              <p>
                Vesl can help guide our clients from iterative, manual data collection systems into
                an integrated platform. This integrated platform serves as the backbone to support
                implementation of predictive analytics and deep learning.{" "}
              </p>
              <img
                src="/images/data_services_slide.png"
                alt={"Data Analytics and Development Applications & Services"}
              />
            </div>
          </div>
        </div>
      </section>
      {/* END SERVICES*/}
      {/*START PortFolio*/}

      {/*
        <section id="contact">
          <div className="container">

          </div>
        </section>
        <footer id="footer">
          <div className="footer-copyright">
            <div className="container"></div>
          </div>
        </footer>
        */}

      {/* Add your custom JavaScript code here */}
    </>
  );
};

export default LandingPage;
