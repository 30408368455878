import DataGridTable from "../DataGridTable/DataGridTable";
import { Downtime, DowntimeCategoryArray } from "../../handlers/downtime";
import { useDowntimes } from "../../hooks/useDowntimes";
import { GridCellParams, GridColDef, useGridApiRef } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { useEffect } from "react";

function toSentenceCase(s: string): string {
  const result = s.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
}

const columns: GridColDef[] = [
  {
    field: 'reporter',
    headerName: 'Reporter',
    headerAlign: 'left',
    align: 'left',
    flex: 1,
  },
  {
    field: "startTime",
    headerName: "Start Time",
    headerAlign: "left",
    align: "left",
    valueFormatter: (value?) => dayjs(value).format('MM/DD/YYYY HH:mm'),
    flex: 1,
  },
  {
    field: "endTime",
    headerName: "End Time",
    headerAlign: "left",
    align: "left",
    valueFormatter: (value) => value && dayjs(value).format('MM/DD/YYYY HH:mm'),
    flex: 1,
  },
  {
    field: "category",
    headerName: "Category",
    headerAlign: "left",
    align: "left",
    valueFormatter: (value: number) => toSentenceCase(DowntimeCategoryArray[value]),
    flex: 1,
  },
  {
    field: "equipment",
    headerName: "Equipment",
    headerAlign: "left",
    align: "left",
    flex: 1,
  },
  {
    field: "costCenter",
    headerName: "Cost Center",
    headerAlign: "left",
    align: "left",
    flex: 1,
  },
  {
    field: "lostSlice",
    headerName: "Lost Slice (hours)",
    headerAlign: "right",
    align: "right",
    type: 'number',
    flex: 1,
  },
  {
    field: "lostSugarProduction",
    headerName: "Lost Sugar (hours)",
    headerAlign: "right",
    align: "right",
    type: 'number',
    flex: 1,
  },
  {
    field: "reason",
    headerName: "Reason",
    headerAlign: "left",
    align: "left",
    flex: 1,
  }
]

const DowntimeList = () => {
  const downtimes = useDowntimes();

  return <DataGridTable columns={columns} rows={downtimes}/>
};

export default DowntimeList;