import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import LandingPage from "pages/LandingPage";
import { BaseDash } from "BaseDash";

// Landing Page  is What builds the Whole Dash
//  Main component should only be shown once the user is authenticated
export const Splash = () => {
  return (
    <>
      <UnauthenticatedTemplate>
        <LandingPage />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <BaseDash />
      </AuthenticatedTemplate>
    </>
  );
};
