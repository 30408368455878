import React, { useState } from "react";
import { DataGrid, GridEditInputCell } from "@mui/x-data-grid";
import sampleLocationOptions from "../../layouts/dashboards/LabDash/SampleLocationsCommon";
import analyteOptions from "../../layouts/dashboards/LabDash/AnalytesCommon";
import { Box, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import { createSamples, getAnalyteOptions } from "../../handlers/samples";
import NoRowsOverlay from "./NoRowsOverlay";
import { LoadingButton } from "@mui/lab";
import Typography from "@mui/material/Typography";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import "../../layouts/dashboards/LabDash/Page.css";
import AutofillForm from "./AutofillForm";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { styled } from "@mui/material/styles";
import { v4 as uuidv4 } from "uuid";
import { getAnalyteLimits } from "../../handlers/analytes";

const StyledTooltip = styled(({ className, open, title, children }) => (
  <Tooltip open={open} title={title} classes={{ popper: className }}>
    {children}
  </Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

function ValueInputCell(props) {
  const { error, errorMsg } = props;

  return (
    <StyledTooltip open={!!error} title={errorMsg}>
      <GridEditInputCell {...props} />
    </StyledTooltip>
  );
}

const LabEntry = () => {
  const initialRowCount = 5;
  const [isSaving, setIsSaving] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [timeOfSubmission, setTimeOfSubmission] = useState(null);

  const [rows, setRows] = useState(
    Array.from({ length: initialRowCount }, () => ({
      id: uuidv4(),
      sampleLocation: "",
      analyte: "",
      collectDT: new Date(),
      sampleTestDT: new Date(),
      resultValue: "",
      upperLimit: 100,
      lowerLimit: 0,
    })),
  );

  const handleAnalyteChange = (params) => {
    const rowData = params.row;
    const limits = getAnalyteLimits(params.props.value);
    console.log(rows);
    setRows(
      rows.map((row) =>
        row.id === params.row.id
          ? {
            ...rowData,
            sampleLocation: params.row.sampleLocation,
            analyte: params.props.value,
            lowerLimit: limits.lowerLimit,
            upperLimit: limits.upperLimit,
          }
          : row,
      ),
    );
    return params.props;
  };

  const handleSubmit = async () => {
    setIsSaving(true);
    await createSamples(rows);
    setIsSaving(false);
    setTimeOfSubmission(new Date());
    setHasSubmitted(true);
  };

  const handleDeleteRow = (id) => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleAddRows = (nRows, rowData) => {
    if (nRows < 1) return;
    if (!rowData) {
      const newRows = Array.from({ length: nRows }, () => ({
        id: uuidv4(),
        sampleLocation: "",
        analyte: "",
        collectDT: new Date(),
        sampleTestDT: new Date(),
        resultValue: "",
        upperLimit: 100,
        lowerLimit: 0,
      }));
      setRows([...rows, ...newRows]);
      return;
    }

    let newRows = rowData.analytes.flatMap((analyte) =>
      Array.from({ length: nRows }, () => ({
        id: uuidv4(),
        sampleLocation: rowData.sampleLocation,
        analyte: analyte,
        collectDT: rowData.collectDT,
        sampleTestDT: rowData.sampleTestDT,
        resultValue: rowData.resultValue,
        upperLimit: 100,
        lowerLimit: 0,
      })),
    );
    if (rowData.analytes.length === 0) {
      newRows = Array.from({ length: nRows }, () => ({
        id: uuidv4(),
        sampleLocation: rowData.sampleLocation,
        analyte: "",
        collectDT: rowData.collectDT,
        sampleTestDT: rowData.sampleTestDT,
        resultValue: rowData.resultValue,
        upperLimit: 100,
        lowerLimit: 0,
      }));
    }

    setRows([...newRows, ...rows]);
  };

  const renderEditValueCell = (params) => {
    return <ValueInputCell {...params} />;
  };

  const columns = [
    {
      field: "sampleLocation",
      headerName: "Sample Location",
      flex: 1,
      type: "singleSelect",
      valueOptions: sampleLocationOptions,
      editable: true,
    },
    {
      field: "analyte",
      headerName: "Analyte",
      flex: 1,
      type: "singleSelect",
      valueOptions: ({ row }) => {
        if (!row || !row.sampleLocation) return analyteOptions;
        return getAnalyteOptions(row.sampleLocation);
      },
      editable: true,
      preProcessEditCellProps: handleAnalyteChange,
    },
    {
      field: "sampleTestDT",
      headerName: "Sample Test Time",
      flex: 1,
      editable: true,
      type: "dateTime",
    },
    {
      field: "resultValue",
      headerName: "Value",
      flex: 1,
      editable: true,
      preProcessEditCellProps: (params) => {
        const value = parseFloat(params.props.value);
        const inRange = value >= params.row.lowerLimit && value <= params.row.upperLimit;
        if (!inRange) {
          return {
            ...params.props,
            error: true,
            errorMsg: `Value must be between ${params.row.lowerLimit.toPrecision(3)} and ${params.row.upperLimit.toPrecision(3)}`,
          };
        }
        return {
          ...params.props,
          error: false,
          errorMsg: "",
        };
      },
      renderEditCell: renderEditValueCell,
    },
    {
      field: "actions",
      headerName: "Delete",
      flex: 0.5,
      renderCell: ({ row }) => (
        <IconButton size="small" onClick={() => handleDeleteRow(row.id)}>
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  const handleRowUpdate = (newRow) => {
    console.log("Updating row", newRow);
    setRows(
      rows.map((row) => (row.id === newRow.id ? { ...row, ...newRow } : row)),
    );
    return newRow;
  };

  const handleRowUpdateError = (newRow) => {
    console.log("Error updating row", newRow);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "left",
            padding: "10px",
            width: "100%",
          }}
        >
          <Typography variant="h3">Lab Entry</Typography>
          <AutofillForm addRows={handleAddRows} />
        </Box>
        <Box
          sx={{
            width: "100%",
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 25,
                },
              },
            }}
            pageSizeOptions={[5, 10, 25, 100]}
            disableRowSelectionOnClick
            processRowUpdate={handleRowUpdate}
            processRowUpdateError={handleRowUpdateError}
            slots={{
              noRowsOverlay: () => NoRowsOverlay({ addRows: handleAddRows }),
            }}
          />
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "right",
            padding: "10px",
            width: "100%",
            gap: "10px",
          }}
        >
          <LoadingButton
            loading={isSaving}
            onClick={handleSubmit}
            startIcon={<SaveIcon />}
            loadingPosition="start"
            variant="contained"
            disabled={hasSubmitted}
          >
            <span>Save</span>
          </LoadingButton>
          {hasSubmitted && <Typography variant="body2">Submitted at {timeOfSubmission?.toLocaleString()}</Typography>}
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default LabEntry;
