import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import LabEntry from "../../components/LabEntryDataGrid/LabEntrySheetDataGrid.jsx";


const LabEntryDataGridPage = () => {
  return (
    <DashboardLayout>
      <LabEntry />
    </DashboardLayout>
  );
};

export default LabEntryDataGridPage;