import { CellContext, ColumnDef } from "@tanstack/react-table";
import { AnalyteHourGrouping } from "../../hooks/useFilteredSampleStream";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useEffect, useState } from "react";
import { getStyleForValue } from "../../handlers/operatingWindow";
import { cn } from "../../lib/utils";
import { SampleFilter } from "../../handlers/samples";

dayjs.extend(utc);
dayjs.extend(timezone);

function mean(values?: number[]) {
  if (!values || values.length === 0) {
    return;
  }
  return values.reduce((acc, value) => acc + value, 0) / values.length;
}

const timeSeriesColumns = (current: Dayjs, reverse?: boolean): ColumnDef<AnalyteHourGrouping>[] => {
  // create a GridColDef for each of the last 24 hours
  const ret = Array.from({ length: 24 }, (_, i) => {
    const date = current.add(1, "day").subtract(i, "hour");
    return {
      id: date.format("YYYY-MM-DDTHH"),
      header: () => <div className={"text-right font-medium"}> {date.format("h A")}</div>,
      accessorFn: (row: AnalyteHourGrouping) =>
        mean(row.values[date.format("YYYY-MM-DDTHH")])?.toPrecision(3),
      cell: ({ row }: CellContext<AnalyteHourGrouping, unknown>) => {
        const value = row.getValue(date.format("YYYY-MM-DDTHH")) as number;
        const style = getStyleForValue(value, row.original.operatingWindow);
        return <div className={cn("text-right", "font-medium", style)}>{value}</div>;
      },
    };
  });
  if (reverse) {
    return ret.reverse();
  }
  return ret;
};

const baseColumns: ColumnDef<AnalyteHourGrouping>[] = [
  {
    id: "sampleLocation",
    accessorKey: "sampleLocation",
    header: () => <div className={"text-left font-medium"}>Sample Location</div>,
    cell: ({ row }: CellContext<AnalyteHourGrouping, unknown>) => {
      return <div className={"text-left font-medium"}>{row.getValue("sampleLocation")}</div>;
    },
  },
  {
    id: "analyte",
    accessorKey: "analyte",
    header: () => <div className={"text-right font-medium"}>Analyte</div>,
    cell: ({ row }: CellContext<AnalyteHourGrouping, unknown>) => {
      return <div className={"text-left font-medium"}>{row.getValue("analyte")}</div>;
    },
  },
];

export function useColumns(filter: SampleFilter) {
  dayjs().subtract(1, "day");
  const [reverse, setReverse] = useState(false);

  const [columns, setColumns] = useState<ColumnDef<AnalyteHourGrouping>[]>([]);
  useEffect(() => {
    setColumns([
      ...baseColumns,
      ...timeSeriesColumns(filter.startDate || dayjs().subtract(1, "day"), reverse),
    ]);
  }, [filter, reverse]);
  return {
    columns,
    setReverse,
    reverse,
  };
}
