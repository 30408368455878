import { LabSheetPage } from "./LabSheetPage";
import analyteLimits from "../../data/analyteLimits.json";
import { Datum, UseOpcDataOptions } from "../../handlers/opcData";
import { AnalyteHourGrouping } from "../../hooks/useFilteredSampleStream";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const sampleLocations = [
  "Cossette",
  "Pr Pulp Water",
  "Diff Supply Water",
  "Raw Jce Exit",
  "PreLimer C#1",
  "PreLimer C#2",
  "PreLimer C#3",
  "PreLimer C#4",
  "PreLimer C#5",
  "PreLimer C#6",
  "Milk of Lime",
  "MainLimer",
  "1st Carb",
  "2nd Carb",
  "Thin Juice",
  "Evap Thick Juice",
  "Clarifier",
  "Lime Flume",
  "Main Sewer",
  "Seal Tank",
];

const analytes = [
  "% Sugar",
  "Purity",
  "pH",
  "SO2",
  "RDS",
  "CaO",
  "Brix",
  "Alkalinity",
  "Purity",
  "Lime Salts",
  "Color",
];

const rows: [string, string][] = [
  ["Cossette", "Tons sliced per hour"],
  ["Cossette", "% Sugar"],
  ["Cossette", "Purity"],
  ["Pr Pulp Water", "% Sugar"],
  ["Pr Pulp Water", "Purity"],
  ["Diff Supply Water", "pH"],
  ["Diff Supply Water", "SO2"],
  ["Raw Jce Exit", "pH"],
  ["Raw Jce Exit", "RDS"],
  ["Raw Jce Exit", "Purity"],
  ["PreLimer C#1", "pH"],
  ["PreLimer C#2", "pH"],
  ["PreLimer C#3", "pH"],
  ["PreLimer C#4", "pH"],
  ["PreLimer C#5", "pH"],
  ["PreLimer C#6", "pH"],
  ["PreLimer C#6", "CaO"],
  ["Milk of Lime", "Brix"],
  ["Milk of Lime", "CaO"],
  ["MainLimer", "pH"],
  ["MainLimer", "CaO"],
  ["1st Carb", "pH"],
  ["1st Carb", "Alkalinity"],
  ["2nd Carb", "pH"],
  ["2nd Carb", "Alkalinity"],
  ["Thin Juice", "pH"],
  ["Thin Juice", "Purity"],
  ["Thin Juice", "Lime Salts"],
  ["Thin Juice", "RDS"],
  ["Thin Juice", "Color"],
  ["Evap Thick Juice", "pH"],
  ["Evap Thick Juice", "Purity"],
  ["Evap Thick Juice", "RDS"],
  ["Evap Thick Juice", "Color"],
  ["Clarifier", "% Sugar"],
  ["Clarifier", "pH"],
  ["Lime Flume", "% Sugar"],
  ["Main Sewer", "% Sugar"],
  ["Seal Tank", "% Sugar"],
  ["Seal Tank", "pH"],
];

const opcData: UseOpcDataOptions<Record<string, AnalyteHourGrouping>> = {
  reducer(data) {
    const hourMinMax = data.reduce(addDatum, {});
    return { "Cossette.Tons sliced per hour": toAnalyteHourGrouping(hourMinMax) };
  },
  tags: [40452],
  name: "Tons Sliced",
};

interface HourGroup {
  [hour: string]: number[];
}

function toAnalyteHourGrouping(hourMinMax: HourGroup): AnalyteHourGrouping {
  return {
    sampleLocation: "Cossette",
    analyte: "Tons sliced per hour",
    values: toValues(hourMinMax),
    operatingWindow: {
      min: 125,
      max: 135,
    },
  };
}

function toValues(hourMinMax: HourGroup): Record<string, number[]> {
  return Object.entries(hourMinMax).reduce((acc, [hour, values]) => {
    return { ...acc, [hour]: values };
  }, {});
}

function addDatum(acc: HourGroup, { time, value }: Datum): HourGroup {
  const dateHour = dayjs.utc(time.toISOString()).local().format("YYYY-MM-DDTHH");
  const existing = acc[dateHour];
  if (!existing) {
    return {
      ...acc,
      [dateHour]: [value as number],
    };
  }
  return {
    ...acc,
    [dateHour]: [...existing, value as number],
  };
}

export const BeetEndPage = () => {
  return (
    <LabSheetPage
      sampleLocations={sampleLocations}
      analytes={analytes}
      rows={rows}
      opcData={opcData}
    />
  );
};
