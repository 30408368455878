const SampleLoc2Analyte = [
  {
    name: "None",
    children: [
      {
        name: "Screen +35",
        children: [],
      },
      {
        name: "Screen +65",
        children: [],
      },
      {
        name: "Screen -100",
        children: [],
      },
      {
        name: "MA",
        children: [],
      },
      {
        name: "CV",
        children: [],
      },
      {
        name: "pH",
        children: [],
      },
      {
        name: "Brix",
        children: [],
      },
      {
        name: "Purity",
        children: [],
      },
      {
        name: "RDS",
        children: [],
      },
      {
        name: "% Sugar",
        children: [],
      },
      {
        name: "Color",
        children: [],
      },
      {
        name: "Alkalinity",
        children: [],
      },
      {
        name: "CaO",
        children: [],
      },
      {
        name: "SO2",
        children: [],
      },
      {
        name: "V Speck",
        children: [],
      },
      {
        name: "% Moisture",
        children: [],
      },
      {
        name: "% Ash",
        children: [],
      },
      {
        name: "Lime Salts",
        children: [],
      },
      {
        name: "Sugar Traces",
        children: [],
      },
    ],
  },
  {
    type: "SampleLocation",
    name: "1st Carb",
    children: [
      {
        type: "Analyte",
        name: "% Sugar",
        key: "SampleLocation-1st Carb-Analyte-% Sugar",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 0.07046570933039262,
            key: "SampleLocation-1st Carb-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 0.12153429066960741,
            key: "SampleLocation-1st Carb-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Alkalinity",
        key: "SampleLocation-1st Carb-Analyte-Alkalinity",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -11.870711379450453,
            key: "SampleLocation-1st Carb-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 13.020364130259514,
            key: "SampleLocation-1st Carb-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "CaO",
        key: "SampleLocation-1st Carb-Analyte-CaO",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -17.87271596343222,
            key: "SampleLocation-1st Carb-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 22.6785036346651,
            key: "SampleLocation-1st Carb-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Loads",
        key: "SampleLocation-1st Carb-Analyte-Loads",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 14.0,
            key: "SampleLocation-1st Carb-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 14.0,
            key: "SampleLocation-1st Carb-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "None",
        key: "SampleLocation-1st Carb-Analyte-None",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 0.0,
            key: "SampleLocation-1st Carb-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 0.0,
            key: "SampleLocation-1st Carb-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-1st Carb-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 9.580057178092693,
            key: "SampleLocation-1st Carb-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 12.70768493439016,
            key: "SampleLocation-1st Carb-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-1st Carb",
  },
  {
    type: "SampleLocation",
    name: "2nd Carb",
    children: [
      {
        type: "Analyte",
        name: "% Sugar",
        key: "SampleLocation-2nd Carb-Analyte-% Sugar",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-2nd Carb-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-2nd Carb-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Alkalinity",
        key: "SampleLocation-2nd Carb-Analyte-Alkalinity",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -6.478851570721013,
            key: "SampleLocation-2nd Carb-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 6.927252087516879,
            key: "SampleLocation-2nd Carb-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "CaO",
        key: "SampleLocation-2nd Carb-Analyte-CaO",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-2nd Carb-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-2nd Carb-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Color",
        key: "SampleLocation-2nd Carb-Analyte-Color",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-2nd Carb-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-2nd Carb-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "MA",
        key: "SampleLocation-2nd Carb-Analyte-MA",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-2nd Carb-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-2nd Carb-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "None",
        key: "SampleLocation-2nd Carb-Analyte-None",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-2nd Carb-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-2nd Carb-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "SO2",
        key: "SampleLocation-2nd Carb-Analyte-SO2",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -0.027809839166302502,
            key: "SampleLocation-2nd Carb-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 0.058476505832969164,
            key: "SampleLocation-2nd Carb-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-2nd Carb-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 7.454883590536401,
            key: "SampleLocation-2nd Carb-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 11.493949390436118,
            key: "SampleLocation-2nd Carb-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-2nd Carb",
  },
  {
    type: "SampleLocation",
    name: "2nd Carb ",
    children: [
      {
        type: "Analyte",
        name: "Alkalinity",
        key: "SampleLocation-2nd Carb -Analyte-Alkalinity",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-2nd Carb -Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-2nd Carb -Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-2nd Carb ",
  },
  {
    type: "SampleLocation",
    name: "Aff Fillmass",
    children: [
      {
        type: "Analyte",
        name: "% Sugar",
        key: "SampleLocation-Aff Fillmass-Analyte-% Sugar",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Aff Fillmass-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Aff Fillmass-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "None",
        key: "SampleLocation-Aff Fillmass-Analyte-None",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 76.42983189333187,
            key: "SampleLocation-Aff Fillmass-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 81.04016810666813,
            key: "SampleLocation-Aff Fillmass-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Purity",
        key: "SampleLocation-Aff Fillmass-Analyte-Purity",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 68.98034882358078,
            key: "SampleLocation-Aff Fillmass-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 100.16679403356207,
            key: "SampleLocation-Aff Fillmass-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "RDS",
        key: "SampleLocation-Aff Fillmass-Analyte-RDS",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 73.18586587600645,
            key: "SampleLocation-Aff Fillmass-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 98.41777995732689,
            key: "SampleLocation-Aff Fillmass-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-Aff Fillmass-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -67.60377113267182,
            key: "SampleLocation-Aff Fillmass-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 165.37377113267183,
            key: "SampleLocation-Aff Fillmass-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Aff Fillmass",
  },
  {
    type: "SampleLocation",
    name: "Affination",
    children: [
      {
        type: "Analyte",
        name: "Color",
        key: "SampleLocation-Affination-Analyte-Color",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -262.134464577219,
            key: "SampleLocation-Affination-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 3234.3916074343615,
            key: "SampleLocation-Affination-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Purity",
        key: "SampleLocation-Affination-Analyte-Purity",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 95.54863739145298,
            key: "SampleLocation-Affination-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 101.53494469809925,
            key: "SampleLocation-Affination-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Affination",
  },
  {
    type: "SampleLocation",
    name: "Affination Syr",
    children: [
      {
        type: "Analyte",
        name: "% Sugar",
        key: "SampleLocation-Affination Syr-Analyte-% Sugar",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 49.87838871964771,
            key: "SampleLocation-Affination Syr-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 78.8901827089237,
            key: "SampleLocation-Affination Syr-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Lime Salts",
        key: "SampleLocation-Affination Syr-Analyte-Lime Salts",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Affination Syr-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Affination Syr-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Purity",
        key: "SampleLocation-Affination Syr-Analyte-Purity",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 40.13516554459516,
            key: "SampleLocation-Affination Syr-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 106.01450364906438,
            key: "SampleLocation-Affination Syr-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "RDS",
        key: "SampleLocation-Affination Syr-Analyte-RDS",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 60.904953636352026,
            key: "SampleLocation-Affination Syr-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 94.20349080809243,
            key: "SampleLocation-Affination Syr-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-Affination Syr-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -31.658244436750813,
            key: "SampleLocation-Affination Syr-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 137.85824443675082,
            key: "SampleLocation-Affination Syr-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Affination Syr",
  },
  {
    type: "SampleLocation",
    name: "Clarifier",
    children: [
      {
        type: "Analyte",
        name: "% Moisture",
        key: "SampleLocation-Clarifier-Analyte-% Moisture",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Clarifier-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Clarifier-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "% Sugar",
        key: "SampleLocation-Clarifier-Analyte-% Sugar",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -6.49856503761965,
            key: "SampleLocation-Clarifier-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 8.988328351229118,
            key: "SampleLocation-Clarifier-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "CaO",
        key: "SampleLocation-Clarifier-Analyte-CaO",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Clarifier-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Clarifier-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "MA",
        key: "SampleLocation-Clarifier-Analyte-MA",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 10.12439033455901,
            key: "SampleLocation-Clarifier-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 14.225609665440992,
            key: "SampleLocation-Clarifier-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-Clarifier-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 4.36575988804258,
            key: "SampleLocation-Clarifier-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 15.162979114889975,
            key: "SampleLocation-Clarifier-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Clarifier",
  },
  {
    type: "SampleLocation",
    name: "Cold Limer",
    children: [
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-Cold Limer-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Cold Limer-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Cold Limer-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Cold Limer",
  },
  {
    type: "SampleLocation",
    name: "ColdLimer",
    children: [
      {
        type: "Analyte",
        name: "CaO",
        key: "SampleLocation-ColdLimer-Analyte-CaO",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-ColdLimer-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-ColdLimer-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-ColdLimer",
  },
  {
    type: "SampleLocation",
    name: "Cooling Tower",
    children: [
      {
        type: "Analyte",
        name: "% Sugar",
        key: "SampleLocation-Cooling Tower-Analyte-% Sugar",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -4.944920852413866,
            key: "SampleLocation-Cooling Tower-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 5.696689669618167,
            key: "SampleLocation-Cooling Tower-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Alkalinity",
        key: "SampleLocation-Cooling Tower-Analyte-Alkalinity",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Cooling Tower-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Cooling Tower-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-Cooling Tower-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 5.387103755900322,
            key: "SampleLocation-Cooling Tower-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 9.877101095851701,
            key: "SampleLocation-Cooling Tower-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Cooling Tower",
  },
  {
    type: "SampleLocation",
    name: "Cossette",
    children: [
      {
        type: "Analyte",
        name: "% Moisture",
        key: "SampleLocation-Cossette-Analyte-% Moisture",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Cossette-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Cossette-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "% Sugar",
        key: "SampleLocation-Cossette-Analyte-% Sugar",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 10.459704613629729,
            key: "SampleLocation-Cossette-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 24.548075934998703,
            key: "SampleLocation-Cossette-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "None",
        key: "SampleLocation-Cossette-Analyte-None",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Cossette-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Cossette-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Purity",
        key: "SampleLocation-Cossette-Analyte-Purity",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 73.0642826153256,
            key: "SampleLocation-Cossette-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 101.14111968352498,
            key: "SampleLocation-Cossette-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "RDS",
        key: "SampleLocation-Cossette-Analyte-RDS",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Cossette-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Cossette-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "SO2",
        key: "SampleLocation-Cossette-Analyte-SO2",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -88.71344549358483,
            key: "SampleLocation-Cossette-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 367.38011216025154,
            key: "SampleLocation-Cossette-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-Cossette-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -35.3440603335348,
            key: "SampleLocation-Cossette-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 131.9040603335348,
            key: "SampleLocation-Cossette-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Cossette",
  },
  {
    type: "SampleLocation",
    name: "Diff Supl Water",
    children: [
      {
        type: "Analyte",
        name: "SO2",
        key: "SampleLocation-Diff Supl Water-Analyte-SO2",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -103.33584339307752,
            key: "SampleLocation-Diff Supl Water-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 272.47870053593465,
            key: "SampleLocation-Diff Supl Water-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-Diff Supl Water-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 4.556261784968571,
            key: "SampleLocation-Diff Supl Water-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 7.163738215031428,
            key: "SampleLocation-Diff Supl Water-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Diff Supl Water",
  },
  {
    type: "SampleLocation",
    name: "Diff Supply Water",
    children: [
      {
        type: "Analyte",
        name: "% Sugar",
        key: "SampleLocation-Diff Supply Water-Analyte-% Sugar",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -6.0743627269605796,
            key: "SampleLocation-Diff Supply Water-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 92.56936272696058,
            key: "SampleLocation-Diff Supply Water-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Brix",
        key: "SampleLocation-Diff Supply Water-Analyte-Brix",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Diff Supply Water-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Diff Supply Water-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Color",
        key: "SampleLocation-Diff Supply Water-Analyte-Color",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 784.4399000076785,
            key: "SampleLocation-Diff Supply Water-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 2348.5600999923217,
            key: "SampleLocation-Diff Supply Water-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Lime Salts",
        key: "SampleLocation-Diff Supply Water-Analyte-Lime Salts",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Diff Supply Water-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Diff Supply Water-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "MA",
        key: "SampleLocation-Diff Supply Water-Analyte-MA",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Diff Supply Water-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Diff Supply Water-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Purity",
        key: "SampleLocation-Diff Supply Water-Analyte-Purity",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 73.21032717372776,
            key: "SampleLocation-Diff Supply Water-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 108.06700978279397,
            key: "SampleLocation-Diff Supply Water-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "RDS",
        key: "SampleLocation-Diff Supply Water-Analyte-RDS",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -0.42692680837292585,
            key: "SampleLocation-Diff Supply Water-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 32.611786143411294,
            key: "SampleLocation-Diff Supply Water-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "SO2",
        key: "SampleLocation-Diff Supply Water-Analyte-SO2",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 55.055192255835095,
            key: "SampleLocation-Diff Supply Water-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 266.9968411122567,
            key: "SampleLocation-Diff Supply Water-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Screen -100",
        key: "SampleLocation-Diff Supply Water-Analyte-Screen -100",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 94.74516600406096,
            key: "SampleLocation-Diff Supply Water-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 185.25483399593904,
            key: "SampleLocation-Diff Supply Water-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-Diff Supply Water-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 1.0842190506521563,
            key: "SampleLocation-Diff Supply Water-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 10.795879471515331,
            key: "SampleLocation-Diff Supply Water-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Diff Supply Water",
  },
  {
    type: "SampleLocation",
    name: "Diff Supply Wtr",
    children: [
      {
        type: "Analyte",
        name: "SO2",
        key: "SampleLocation-Diff Supply Wtr-Analyte-SO2",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -110.47413829115973,
            key: "SampleLocation-Diff Supply Wtr-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 253.71413829115974,
            key: "SampleLocation-Diff Supply Wtr-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-Diff Supply Wtr-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 4.8920353544371835,
            key: "SampleLocation-Diff Supply Wtr-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 6.277964645562816,
            key: "SampleLocation-Diff Supply Wtr-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Diff Supply Wtr",
  },
  {
    type: "SampleLocation",
    name: "Diff#1 Cossette",
    children: [
      {
        type: "Analyte",
        name: "Purity",
        key: "SampleLocation-Diff#1 Cossette-Analyte-Purity",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 86.52489345456111,
            key: "SampleLocation-Diff#1 Cossette-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 89.00177321210556,
            key: "SampleLocation-Diff#1 Cossette-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Diff#1 Cossette",
  },
  {
    type: "SampleLocation",
    name: "Dowm thin ",
    children: [
      {
        type: "Analyte",
        name: "Lime Salts",
        key: "SampleLocation-Dowm thin -Analyte-Lime Salts",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Dowm thin -Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Dowm thin -Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Dowm thin ",
  },
  {
    type: "SampleLocation",
    name: "Down Thin",
    children: [
      {
        type: "Analyte",
        name: "Lime Salts",
        key: "SampleLocation-Down Thin-Analyte-Lime Salts",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Down Thin-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Down Thin-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Purity",
        key: "SampleLocation-Down Thin-Analyte-Purity",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Down Thin-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Down Thin-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "RDS",
        key: "SampleLocation-Down Thin-Analyte-RDS",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 10.930395385192895,
            key: "SampleLocation-Down Thin-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 15.229604614807105,
            key: "SampleLocation-Down Thin-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-Down Thin-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Down Thin-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Down Thin-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Down Thin",
  },
  {
    type: "SampleLocation",
    name: "Down Thin Juice",
    children: [
      {
        type: "Analyte",
        name: "% Sugar",
        key: "SampleLocation-Down Thin Juice-Analyte-% Sugar",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 13.894882575279595,
            key: "SampleLocation-Down Thin Juice-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 86.9376174247204,
            key: "SampleLocation-Down Thin Juice-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Brix",
        key: "SampleLocation-Down Thin Juice-Analyte-Brix",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -4.218244469443839,
            key: "SampleLocation-Down Thin Juice-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 85.83824446944385,
            key: "SampleLocation-Down Thin Juice-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "CV",
        key: "SampleLocation-Down Thin Juice-Analyte-CV",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 0.016287374530559347,
            key: "SampleLocation-Down Thin Juice-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 0.1323792921361073,
            key: "SampleLocation-Down Thin Juice-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Color",
        key: "SampleLocation-Down Thin Juice-Analyte-Color",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -8767.258053600015,
            key: "SampleLocation-Down Thin Juice-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 13272.245579197284,
            key: "SampleLocation-Down Thin Juice-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Lime Salts",
        key: "SampleLocation-Down Thin Juice-Analyte-Lime Salts",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -24.12297730186027,
            key: "SampleLocation-Down Thin Juice-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 25.92191261136332,
            key: "SampleLocation-Down Thin Juice-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Limesalts",
        key: "SampleLocation-Down Thin Juice-Analyte-Limesalts",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 0.1042893218813452,
            key: "SampleLocation-Down Thin Juice-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 0.24571067811865477,
            key: "SampleLocation-Down Thin Juice-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "MA",
        key: "SampleLocation-Down Thin Juice-Analyte-MA",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 9.066005050633885,
            key: "SampleLocation-Down Thin Juice-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 9.263994949366113,
            key: "SampleLocation-Down Thin Juice-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "None",
        key: "SampleLocation-Down Thin Juice-Analyte-None",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Down Thin Juice-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Down Thin Juice-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Purity",
        key: "SampleLocation-Down Thin Juice-Analyte-Purity",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -24.78886319826445,
            key: "SampleLocation-Down Thin Juice-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 214.96211381861184,
            key: "SampleLocation-Down Thin Juice-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "RDS",
        key: "SampleLocation-Down Thin Juice-Analyte-RDS",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 6.377514266156276,
            key: "SampleLocation-Down Thin Juice-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 21.488655774329555,
            key: "SampleLocation-Down Thin Juice-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "SO2",
        key: "SampleLocation-Down Thin Juice-Analyte-SO2",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -25.868522855569992,
            key: "SampleLocation-Down Thin Juice-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 106.3349385182206,
            key: "SampleLocation-Down Thin Juice-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Screen -100",
        key: "SampleLocation-Down Thin Juice-Analyte-Screen -100",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 1093.9878844757764,
            key: "SampleLocation-Down Thin Juice-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 2185.345448857557,
            key: "SampleLocation-Down Thin Juice-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-Down Thin Juice-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -1.230064775334725,
            key: "SampleLocation-Down Thin Juice-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 19.799836248935986,
            key: "SampleLocation-Down Thin Juice-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "purity",
        key: "SampleLocation-Down Thin Juice-Analyte-purity",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 92.50331223656862,
            key: "SampleLocation-Down Thin Juice-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 94.08335443009805,
            key: "SampleLocation-Down Thin Juice-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Down Thin Juice",
  },
  {
    type: "SampleLocation",
    name: "Down Thin Juicet",
    children: [
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-Down Thin Juicet-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Down Thin Juicet-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Down Thin Juicet-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Down Thin Juicet",
  },
  {
    type: "SampleLocation",
    name: "Evap Thick Jce",
    children: [
      {
        type: "Analyte",
        name: "% Sugar",
        key: "SampleLocation-Evap Thick Jce-Analyte-% Sugar",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 41.784928486487466,
            key: "SampleLocation-Evap Thick Jce-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 51.06840484684587,
            key: "SampleLocation-Evap Thick Jce-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Color",
        key: "SampleLocation-Evap Thick Jce-Analyte-Color",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 1633.3825897840622,
            key: "SampleLocation-Evap Thick Jce-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 2196.8674102159375,
            key: "SampleLocation-Evap Thick Jce-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Purity",
        key: "SampleLocation-Evap Thick Jce-Analyte-Purity",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 90.8065437542093,
            key: "SampleLocation-Evap Thick Jce-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 94.05917053150499,
            key: "SampleLocation-Evap Thick Jce-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "RDS",
        key: "SampleLocation-Evap Thick Jce-Analyte-RDS",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 40.991772165164406,
            key: "SampleLocation-Evap Thick Jce-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 67.41679926340703,
            key: "SampleLocation-Evap Thick Jce-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "RDS-A",
        key: "SampleLocation-Evap Thick Jce-Analyte-RDS-A",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Evap Thick Jce-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Evap Thick Jce-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-Evap Thick Jce-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 7.776640065896189,
            key: "SampleLocation-Evap Thick Jce-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 9.000859934103811,
            key: "SampleLocation-Evap Thick Jce-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Evap Thick Jce",
  },
  {
    type: "SampleLocation",
    name: "Evap Thick Juice",
    children: [
      {
        type: "Analyte",
        name: "% Sugar",
        key: "SampleLocation-Evap Thick Juice-Analyte-% Sugar",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 47.73513372480298,
            key: "SampleLocation-Evap Thick Juice-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 67.36486627519703,
            key: "SampleLocation-Evap Thick Juice-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Brix",
        key: "SampleLocation-Evap Thick Juice-Analyte-Brix",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 49.37496249193782,
            key: "SampleLocation-Evap Thick Juice-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 64.70503750806218,
            key: "SampleLocation-Evap Thick Juice-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Color",
        key: "SampleLocation-Evap Thick Juice-Analyte-Color",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -905.5800303295155,
            key: "SampleLocation-Evap Thick Juice-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 5933.820935610321,
            key: "SampleLocation-Evap Thick Juice-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Lime Salts",
        key: "SampleLocation-Evap Thick Juice-Analyte-Lime Salts",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -36.61611802676775,
            key: "SampleLocation-Evap Thick Juice-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 84.07868945533919,
            key: "SampleLocation-Evap Thick Juice-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "MA",
        key: "SampleLocation-Evap Thick Juice-Analyte-MA",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 7.98031480350419,
            key: "SampleLocation-Evap Thick Juice-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 10.133018529829144,
            key: "SampleLocation-Evap Thick Juice-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Main Limer",
        key: "SampleLocation-Evap Thick Juice-Analyte-Main Limer",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 7.411522368914976,
            key: "SampleLocation-Evap Thick Juice-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 11.088477631085025,
            key: "SampleLocation-Evap Thick Juice-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "PH",
        key: "SampleLocation-Evap Thick Juice-Analyte-PH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Evap Thick Juice-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Evap Thick Juice-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Purity",
        key: "SampleLocation-Evap Thick Juice-Analyte-Purity",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -101.16019928452206,
            key: "SampleLocation-Evap Thick Juice-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 292.6846646395263,
            key: "SampleLocation-Evap Thick Juice-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "RDS",
        key: "SampleLocation-Evap Thick Juice-Analyte-RDS",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -217.84412972072312,
            key: "SampleLocation-Evap Thick Juice-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 339.794910208528,
            key: "SampleLocation-Evap Thick Juice-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Screen -100",
        key: "SampleLocation-Evap Thick Juice-Analyte-Screen -100",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 1817.6232329158634,
            key: "SampleLocation-Evap Thick Juice-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 2728.3767670841366,
            key: "SampleLocation-Evap Thick Juice-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-Evap Thick Juice-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -3.6890932799107894,
            key: "SampleLocation-Evap Thick Juice-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 22.50501982415119,
            key: "SampleLocation-Evap Thick Juice-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "purity",
        key: "SampleLocation-Evap Thick Juice-Analyte-purity",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Evap Thick Juice-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Evap Thick Juice-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Evap Thick Juice",
  },
  {
    type: "SampleLocation",
    name: "Hi Raw",
    children: [
      {
        type: "Analyte",
        name: "Color",
        key: "SampleLocation-Hi Raw-Analyte-Color",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -365.8917415298456,
            key: "SampleLocation-Hi Raw-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 4521.734878784748,
            key: "SampleLocation-Hi Raw-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Purity",
        key: "SampleLocation-Hi Raw-Analyte-Purity",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 93.42132203379528,
            key: "SampleLocation-Hi Raw-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 101.94285707068234,
            key: "SampleLocation-Hi Raw-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Hi Raw",
  },
  {
    type: "SampleLocation",
    name: "Hi Raw Melter",
    children: [
      {
        type: "Analyte",
        name: "% Sugar",
        key: "SampleLocation-Hi Raw Melter-Analyte-% Sugar",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 58.35989441210186,
            key: "SampleLocation-Hi Raw Melter-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 63.51210558789814,
            key: "SampleLocation-Hi Raw Melter-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Brix",
        key: "SampleLocation-Hi Raw Melter-Analyte-Brix",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 60.98117749006091,
            key: "SampleLocation-Hi Raw Melter-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 62.338822509939085,
            key: "SampleLocation-Hi Raw Melter-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Color",
        key: "SampleLocation-Hi Raw Melter-Analyte-Color",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -662.346301797621,
            key: "SampleLocation-Hi Raw Melter-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 9298.761449062698,
            key: "SampleLocation-Hi Raw Melter-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Purity",
        key: "SampleLocation-Hi Raw Melter-Analyte-Purity",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 86.0881539790351,
            key: "SampleLocation-Hi Raw Melter-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 100.51702707946072,
            key: "SampleLocation-Hi Raw Melter-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "RDS",
        key: "SampleLocation-Hi Raw Melter-Analyte-RDS",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 45.6341284619481,
            key: "SampleLocation-Hi Raw Melter-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 82.25703953805188,
            key: "SampleLocation-Hi Raw Melter-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Screen -100",
        key: "SampleLocation-Hi Raw Melter-Analyte-Screen -100",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Hi Raw Melter-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Hi Raw Melter-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-Hi Raw Melter-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 79.01282663649063,
            key: "SampleLocation-Hi Raw Melter-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 105.85384003017604,
            key: "SampleLocation-Hi Raw Melter-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Hi Raw Melter",
  },
  {
    type: "SampleLocation",
    name: "Hi Raw Pan",
    children: [
      {
        type: "Analyte",
        name: "% Sugar",
        key: "SampleLocation-Hi Raw Pan-Analyte-% Sugar",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 57.407590361719045,
            key: "SampleLocation-Hi Raw Pan-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 87.28740963828095,
            key: "SampleLocation-Hi Raw Pan-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Main Limer",
        key: "SampleLocation-Hi Raw Pan-Analyte-Main Limer",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Hi Raw Pan-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Hi Raw Pan-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Number",
        key: "SampleLocation-Hi Raw Pan-Analyte-Number",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -189.16239572991657,
            key: "SampleLocation-Hi Raw Pan-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 591.5623957299165,
            key: "SampleLocation-Hi Raw Pan-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Purity",
        key: "SampleLocation-Hi Raw Pan-Analyte-Purity",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 64.08054427224491,
            key: "SampleLocation-Hi Raw Pan-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 105.7236375459369,
            key: "SampleLocation-Hi Raw Pan-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "RDS",
        key: "SampleLocation-Hi Raw Pan-Analyte-RDS",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 75.42379409447385,
            key: "SampleLocation-Hi Raw Pan-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 107.79759894035202,
            key: "SampleLocation-Hi Raw Pan-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-Hi Raw Pan-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -1.6574036808287396,
            key: "SampleLocation-Hi Raw Pan-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 19.912118706735473,
            key: "SampleLocation-Hi Raw Pan-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Hi Raw Pan",
  },
  {
    type: "SampleLocation",
    name: "Hi Raw Storage",
    children: [
      {
        type: "Analyte",
        name: "% Sugar",
        key: "SampleLocation-Hi Raw Storage-Analyte-% Sugar",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 61.214998451508094,
            key: "SampleLocation-Hi Raw Storage-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 68.03166821515858,
            key: "SampleLocation-Hi Raw Storage-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "% Sugar - Steam",
        key: "SampleLocation-Hi Raw Storage-Analyte-% Sugar - Steam",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Hi Raw Storage-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Hi Raw Storage-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Purity",
        key: "SampleLocation-Hi Raw Storage-Analyte-Purity",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 21.685916115018927,
            key: "SampleLocation-Hi Raw Storage-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 151.6510308685277,
            key: "SampleLocation-Hi Raw Storage-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "RDS",
        key: "SampleLocation-Hi Raw Storage-Analyte-RDS",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 58.860275410340414,
            key: "SampleLocation-Hi Raw Storage-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 88.76322234750711,
            key: "SampleLocation-Hi Raw Storage-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-Hi Raw Storage-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -27.63317105259408,
            key: "SampleLocation-Hi Raw Storage-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 138.54067105259406,
            key: "SampleLocation-Hi Raw Storage-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Hi Raw Storage",
  },
  {
    type: "SampleLocation",
    name: "Lime Flume",
    children: [
      {
        type: "Analyte",
        name: "% Sugar",
        key: "SampleLocation-Lime Flume-Analyte-% Sugar",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -74.69572310115925,
            key: "SampleLocation-Lime Flume-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 90.71560924916685,
            key: "SampleLocation-Lime Flume-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Brix",
        key: "SampleLocation-Lime Flume-Analyte-Brix",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Lime Flume-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Lime Flume-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "CaO",
        key: "SampleLocation-Lime Flume-Analyte-CaO",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -196.02174174228338,
            key: "SampleLocation-Lime Flume-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 212.46396396450558,
            key: "SampleLocation-Lime Flume-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "CaO/Sug",
        key: "SampleLocation-Lime Flume-Analyte-CaO/Sug",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 0.6097561338236047,
            key: "SampleLocation-Lime Flume-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 2.2502438661763953,
            key: "SampleLocation-Lime Flume-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "None",
        key: "SampleLocation-Lime Flume-Analyte-None",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Lime Flume-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Lime Flume-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-Lime Flume-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -0.5370893092036422,
            key: "SampleLocation-Lime Flume-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 11.287089309203642,
            key: "SampleLocation-Lime Flume-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Lime Flume",
  },
  {
    type: "SampleLocation",
    name: "Low Raw",
    children: [
      {
        type: "Analyte",
        name: "% Sugar",
        key: "SampleLocation-Low Raw-Analyte-% Sugar",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Low Raw-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Low Raw-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Brix",
        key: "SampleLocation-Low Raw-Analyte-Brix",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Low Raw-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Low Raw-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Number",
        key: "SampleLocation-Low Raw-Analyte-Number",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -137.9100615093044,
            key: "SampleLocation-Low Raw-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 327.6243472235901,
            key: "SampleLocation-Low Raw-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Purity",
        key: "SampleLocation-Low Raw-Analyte-Purity",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 63.12711386237442,
            key: "SampleLocation-Low Raw-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 90.67750152224095,
            key: "SampleLocation-Low Raw-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "RDS",
        key: "SampleLocation-Low Raw-Analyte-RDS",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 79.24591710599431,
            key: "SampleLocation-Low Raw-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 106.49743732438544,
            key: "SampleLocation-Low Raw-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-Low Raw-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 6.801183645610518,
            key: "SampleLocation-Low Raw-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 9.993158459652637,
            key: "SampleLocation-Low Raw-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Low Raw",
  },
  {
    type: "SampleLocation",
    name: "Low Raw 24Hr",
    children: [
      {
        type: "Analyte",
        name: "Color",
        key: "SampleLocation-Low Raw 24Hr-Analyte-Color",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Low Raw 24Hr-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Low Raw 24Hr-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Low Raw 24Hr",
  },
  {
    type: "SampleLocation",
    name: "Low Raw A",
    children: [
      {
        type: "Analyte",
        name: "Purity",
        key: "SampleLocation-Low Raw A-Analyte-Purity",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Low Raw A-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Low Raw A-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "RDS",
        key: "SampleLocation-Low Raw A-Analyte-RDS",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Low Raw A-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Low Raw A-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-Low Raw A-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Low Raw A-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Low Raw A-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Low Raw A",
  },
  {
    type: "SampleLocation",
    name: "Low Raw Storage",
    children: [
      {
        type: "Analyte",
        name: "% Sugar",
        key: "SampleLocation-Low Raw Storage-Analyte-% Sugar",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 3.0732107882426973,
            key: "SampleLocation-Low Raw Storage-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 97.63345587842397,
            key: "SampleLocation-Low Raw Storage-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Purity",
        key: "SampleLocation-Low Raw Storage-Analyte-Purity",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 57.354753741917406,
            key: "SampleLocation-Low Raw Storage-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 97.72285454433496,
            key: "SampleLocation-Low Raw Storage-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "RDS",
        key: "SampleLocation-Low Raw Storage-Analyte-RDS",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 63.803216377631564,
            key: "SampleLocation-Low Raw Storage-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 92.39880197099228,
            key: "SampleLocation-Low Raw Storage-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-Low Raw Storage-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -25.344836733419363,
            key: "SampleLocation-Low Raw Storage-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 121.38197959056222,
            key: "SampleLocation-Low Raw Storage-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "purity",
        key: "SampleLocation-Low Raw Storage-Analyte-purity",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Low Raw Storage-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Low Raw Storage-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Low Raw Storage",
  },
  {
    type: "SampleLocation",
    name: "Main Limer",
    children: [
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-Main Limer-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Main Limer-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Main Limer-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Main Limer",
  },
  {
    type: "SampleLocation",
    name: "Main Sewer",
    children: [
      {
        type: "Analyte",
        name: "% Moisture",
        key: "SampleLocation-Main Sewer-Analyte-% Moisture",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Main Sewer-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Main Sewer-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "% Sugar",
        key: "SampleLocation-Main Sewer-Analyte-% Sugar",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -3.3335941897581822,
            key: "SampleLocation-Main Sewer-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 4.302493353555526,
            key: "SampleLocation-Main Sewer-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "CaO",
        key: "SampleLocation-Main Sewer-Analyte-CaO",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -0.5367532368147137,
            key: "SampleLocation-Main Sewer-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 14.736753236814714,
            key: "SampleLocation-Main Sewer-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "MA",
        key: "SampleLocation-Main Sewer-Analyte-MA",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Main Sewer-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Main Sewer-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Purity",
        key: "SampleLocation-Main Sewer-Analyte-Purity",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Main Sewer-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Main Sewer-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-Main Sewer-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -49.06973157938458,
            key: "SampleLocation-Main Sewer-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 69.62128822963089,
            key: "SampleLocation-Main Sewer-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Main Sewer",
  },
  {
    type: "SampleLocation",
    name: "MainLimer",
    children: [
      {
        type: "Analyte",
        name: "Brix",
        key: "SampleLocation-MainLimer-Analyte-Brix",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-MainLimer-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-MainLimer-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "CaO",
        key: "SampleLocation-MainLimer-Analyte-CaO",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -1.0833940810075138,
            key: "SampleLocation-MainLimer-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 5.436251223864657,
            key: "SampleLocation-MainLimer-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-MainLimer-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 9.465506712472276,
            key: "SampleLocation-MainLimer-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 14.695971548397292,
            key: "SampleLocation-MainLimer-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-MainLimer",
  },
  {
    type: "SampleLocation",
    name: "Milk of Lime",
    children: [
      {
        type: "Analyte",
        name: "% Sugar",
        key: "SampleLocation-Milk of Lime-Analyte-% Sugar",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -14.278495449100134,
            key: "SampleLocation-Milk of Lime-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 95.69563830624298,
            key: "SampleLocation-Milk of Lime-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Alkalinity",
        key: "SampleLocation-Milk of Lime-Analyte-Alkalinity",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Milk of Lime-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Milk of Lime-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Brix",
        key: "SampleLocation-Milk of Lime-Analyte-Brix",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 20.811653639272546,
            key: "SampleLocation-Milk of Lime-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 41.889628412009515,
            key: "SampleLocation-Milk of Lime-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "CaO",
        key: "SampleLocation-Milk of Lime-Analyte-CaO",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 3.0930893422973345,
            key: "SampleLocation-Milk of Lime-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 25.977365203157206,
            key: "SampleLocation-Milk of Lime-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Purity",
        key: "SampleLocation-Milk of Lime-Analyte-Purity",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 49.743926171038936,
            key: "SampleLocation-Milk of Lime-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 88.94178811467535,
            key: "SampleLocation-Milk of Lime-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "RDS",
        key: "SampleLocation-Milk of Lime-Analyte-RDS",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 61.10326975759235,
            key: "SampleLocation-Milk of Lime-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 101.20673024240764,
            key: "SampleLocation-Milk of Lime-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "SO2",
        key: "SampleLocation-Milk of Lime-Analyte-SO2",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Milk of Lime-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Milk of Lime-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-Milk of Lime-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -19.06337024868522,
            key: "SampleLocation-Milk of Lime-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 46.40798563330061,
            key: "SampleLocation-Milk of Lime-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Milk of Lime",
  },
  {
    type: "SampleLocation",
    name: "Mol Prod",
    children: [
      {
        type: "Analyte",
        name: "% Sugar",
        key: "SampleLocation-Mol Prod-Analyte-% Sugar",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Mol Prod-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Mol Prod-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Purity",
        key: "SampleLocation-Mol Prod-Analyte-Purity",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Mol Prod-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Mol Prod-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "RDS",
        key: "SampleLocation-Mol Prod-Analyte-RDS",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Mol Prod-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Mol Prod-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-Mol Prod-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Mol Prod-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Mol Prod-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Mol Prod",
  },
  {
    type: "SampleLocation",
    name: "Mol Produced",
    children: [
      {
        type: "Analyte",
        name: "% Moisture",
        key: "SampleLocation-Mol Produced-Analyte-% Moisture",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Mol Produced-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Mol Produced-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "% Suga",
        key: "SampleLocation-Mol Produced-Analyte-% Suga",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Mol Produced-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Mol Produced-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "% Sugar",
        key: "SampleLocation-Mol Produced-Analyte-% Sugar",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -285.98713998699856,
            key: "SampleLocation-Mol Produced-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 406.04589585554316,
            key: "SampleLocation-Mol Produced-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Brix",
        key: "SampleLocation-Mol Produced-Analyte-Brix",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -14.527036265531422,
            key: "SampleLocation-Mol Produced-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 112.06703626553143,
            key: "SampleLocation-Mol Produced-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "CaO",
        key: "SampleLocation-Mol Produced-Analyte-CaO",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 11.951471862576144,
            key: "SampleLocation-Mol Produced-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 13.648528137423858,
            key: "SampleLocation-Mol Produced-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Lime Salts",
        key: "SampleLocation-Mol Produced-Analyte-Lime Salts",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 53.25720779386421,
            key: "SampleLocation-Mol Produced-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 55.80279220613579,
            key: "SampleLocation-Mol Produced-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Main Limer",
        key: "SampleLocation-Mol Produced-Analyte-Main Limer",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 5.34047649401425,
            key: "SampleLocation-Mol Produced-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 10.00952350598575,
            key: "SampleLocation-Mol Produced-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Purity",
        key: "SampleLocation-Mol Produced-Analyte-Purity",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 51.793222398149,
            key: "SampleLocation-Mol Produced-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 74.80627710135049,
            key: "SampleLocation-Mol Produced-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "RDS",
        key: "SampleLocation-Mol Produced-Analyte-RDS",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -450.5248384212641,
            key: "SampleLocation-Mol Produced-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 637.9217898775748,
            key: "SampleLocation-Mol Produced-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-Mol Produced-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -1.6204665488114713,
            key: "SampleLocation-Mol Produced-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 19.280659599004522,
            key: "SampleLocation-Mol Produced-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Mol Produced",
  },
  {
    type: "SampleLocation",
    name: "Mol Producedj",
    children: [
      {
        type: "Analyte",
        name: "Purit",
        key: "SampleLocation-Mol Producedj-Analyte-Purit",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Mol Producedj-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Mol Producedj-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Mol Producedj",
  },
  {
    type: "SampleLocation",
    name: "None",
    children: [
      {
        type: "Analyte",
        name: "% Sugar",
        key: "SampleLocation-None-Analyte-% Sugar",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-None-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-None-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "None",
        key: "SampleLocation-None-Analyte-None",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -6.214274394378223,
            key: "SampleLocation-None-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 11.814274394378224,
            key: "SampleLocation-None-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Purity",
        key: "SampleLocation-None-Analyte-Purity",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 59.589707057599654,
            key: "SampleLocation-None-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 94.1436262757337,
            key: "SampleLocation-None-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "RDS",
        key: "SampleLocation-None-Analyte-RDS",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -20.329118749708,
            key: "SampleLocation-None-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 142.11911874970798,
            key: "SampleLocation-None-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-None-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-None-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-None-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-None",
  },
  {
    type: "SampleLocation",
    name: "Nonef",
    children: [
      {
        type: "Analyte",
        name: "% Sugar",
        key: "SampleLocation-Nonef-Analyte-% Sugar",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Nonef-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Nonef-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "CaO",
        key: "SampleLocation-Nonef-Analyte-CaO",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Nonef-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Nonef-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Nonef",
  },
  {
    type: "SampleLocation",
    name: "Pr Pulp Sold",
    children: [
      {
        type: "Analyte",
        name: "% Moisture",
        key: "SampleLocation-Pr Pulp Sold-Analyte-% Moisture",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 72.54782179206407,
            key: "SampleLocation-Pr Pulp Sold-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 76.76217820793593,
            key: "SampleLocation-Pr Pulp Sold-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Pr Pulp Sold",
  },
  {
    type: "SampleLocation",
    name: "Pr Pulp Water",
    children: [
      {
        type: "Analyte",
        name: "% Sugar",
        key: "SampleLocation-Pr Pulp Water-Analyte-% Sugar",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -2.9242460390433322,
            key: "SampleLocation-Pr Pulp Water-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 8.848389997912227,
            key: "SampleLocation-Pr Pulp Water-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Alkalinity",
        key: "SampleLocation-Pr Pulp Water-Analyte-Alkalinity",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Pr Pulp Water-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Pr Pulp Water-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "CaO",
        key: "SampleLocation-Pr Pulp Water-Analyte-CaO",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Pr Pulp Water-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Pr Pulp Water-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "MA",
        key: "SampleLocation-Pr Pulp Water-Analyte-MA",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Pr Pulp Water-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Pr Pulp Water-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Purity",
        key: "SampleLocation-Pr Pulp Water-Analyte-Purity",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Pr Pulp Water-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Pr Pulp Water-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "SO2",
        key: "SampleLocation-Pr Pulp Water-Analyte-SO2",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 1.0760303038033001,
            key: "SampleLocation-Pr Pulp Water-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 2.2639696961966997,
            key: "SampleLocation-Pr Pulp Water-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-Pr Pulp Water-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -49.59062930811596,
            key: "SampleLocation-Pr Pulp Water-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 64.47049597478262,
            key: "SampleLocation-Pr Pulp Water-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Pr Pulp Water",
  },
  {
    type: "SampleLocation",
    name: "PreLimer",
    children: [
      {
        type: "Analyte",
        name: "CaO",
        key: "SampleLocation-PreLimer-Analyte-CaO",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-PreLimer-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-PreLimer-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-PreLimer",
  },
  {
    type: "SampleLocation",
    name: "PreLimer C#1",
    children: [
      {
        type: "Analyte",
        name: "% Sugar",
        key: "SampleLocation-PreLimer C#1-Analyte-% Sugar",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -2.14269954818669,
            key: "SampleLocation-PreLimer C#1-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 10.549366214853357,
            key: "SampleLocation-PreLimer C#1-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-PreLimer C#1-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 6.061064978133095,
            key: "SampleLocation-PreLimer C#1-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 9.869544340863321,
            key: "SampleLocation-PreLimer C#1-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-PreLimer C#1",
  },
  {
    type: "SampleLocation",
    name: "PreLimer C#2",
    children: [
      {
        type: "Analyte",
        name: "% Moisture",
        key: "SampleLocation-PreLimer C#2-Analyte-% Moisture",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-PreLimer C#2-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-PreLimer C#2-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-PreLimer C#2-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -87.78638675615184,
            key: "SampleLocation-PreLimer C#2-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 110.45463954425594,
            key: "SampleLocation-PreLimer C#2-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-PreLimer C#2",
  },
  {
    type: "SampleLocation",
    name: "PreLimer C#3",
    children: [
      {
        type: "Analyte",
        name: "% Moisture",
        key: "SampleLocation-PreLimer C#3-Analyte-% Moisture",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-PreLimer C#3-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-PreLimer C#3-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-PreLimer C#3-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 7.335895487445496,
            key: "SampleLocation-PreLimer C#3-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 10.896450191566851,
            key: "SampleLocation-PreLimer C#3-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-PreLimer C#3",
  },
  {
    type: "SampleLocation",
    name: "PreLimer C#4",
    children: [
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-PreLimer C#4-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 8.618942921723452,
            key: "SampleLocation-PreLimer C#4-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 11.209116779769086,
            key: "SampleLocation-PreLimer C#4-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-PreLimer C#4",
  },
  {
    type: "SampleLocation",
    name: "PreLimer C#5",
    children: [
      {
        type: "Analyte",
        name: "Alkalinity",
        key: "SampleLocation-PreLimer C#5-Analyte-Alkalinity",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-PreLimer C#5-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-PreLimer C#5-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-PreLimer C#5-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 9.939074287634254,
            key: "SampleLocation-PreLimer C#5-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 11.84369943499348,
            key: "SampleLocation-PreLimer C#5-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-PreLimer C#5",
  },
  {
    type: "SampleLocation",
    name: "PreLimer C#6",
    children: [
      {
        type: "Analyte",
        name: "CaO",
        key: "SampleLocation-PreLimer C#6-Analyte-CaO",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -0.8184271856334443,
            key: "SampleLocation-PreLimer C#6-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 2.601327185633444,
            key: "SampleLocation-PreLimer C#6-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Cold Limer",
        key: "SampleLocation-PreLimer C#6-Analyte-Cold Limer",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -7.047870252332157,
            key: "SampleLocation-PreLimer C#6-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 13.175870252332157,
            key: "SampleLocation-PreLimer C#6-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Main Limer",
        key: "SampleLocation-PreLimer C#6-Analyte-Main Limer",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -7.465603073634878,
            key: "SampleLocation-PreLimer C#6-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 17.98960307363488,
            key: "SampleLocation-PreLimer C#6-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-PreLimer C#6-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 10.773649844928546,
            key: "SampleLocation-PreLimer C#6-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 12.362495609616907,
            key: "SampleLocation-PreLimer C#6-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-PreLimer C#6",
  },
  {
    type: "SampleLocation",
    name: "Pulp Presses",
    children: [
      {
        type: "Analyte",
        name: "% Moisture",
        key: "SampleLocation-Pulp Presses-Analyte-% Moisture",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Pulp Presses-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Pulp Presses-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Pulp Presses",
  },
  {
    type: "SampleLocation",
    name: "Pulp from Press",
    children: [
      {
        type: "Analyte",
        name: "% Moisture",
        key: "SampleLocation-Pulp from Press-Analyte-% Moisture",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 74.0231303899845,
            key: "SampleLocation-Pulp from Press-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 83.68871427020967,
            key: "SampleLocation-Pulp from Press-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Pulp from Press",
  },
  {
    type: "SampleLocation",
    name: "Raw Jce Exit",
    children: [
      {
        type: "Analyte",
        name: "Brix",
        key: "SampleLocation-Raw Jce Exit-Analyte-Brix",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Raw Jce Exit-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Raw Jce Exit-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Lime Salts",
        key: "SampleLocation-Raw Jce Exit-Analyte-Lime Salts",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -0.2589838323404019,
            key: "SampleLocation-Raw Jce Exit-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 0.6756504990070686,
            key: "SampleLocation-Raw Jce Exit-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "MA",
        key: "SampleLocation-Raw Jce Exit-Analyte-MA",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Raw Jce Exit-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Raw Jce Exit-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Main Limer",
        key: "SampleLocation-Raw Jce Exit-Analyte-Main Limer",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Raw Jce Exit-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Raw Jce Exit-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Purity",
        key: "SampleLocation-Raw Jce Exit-Analyte-Purity",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 76.0923029682723,
            key: "SampleLocation-Raw Jce Exit-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 103.84645409387458,
            key: "SampleLocation-Raw Jce Exit-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "RDS",
        key: "SampleLocation-Raw Jce Exit-Analyte-RDS",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -167.74647166152823,
            key: "SampleLocation-Raw Jce Exit-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 209.07262761228066,
            key: "SampleLocation-Raw Jce Exit-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "SO2",
        key: "SampleLocation-Raw Jce Exit-Analyte-SO2",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Raw Jce Exit-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Raw Jce Exit-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Temp 'C",
        key: "SampleLocation-Raw Jce Exit-Analyte-Temp 'C",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 24.265546597862915,
            key: "SampleLocation-Raw Jce Exit-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 49.05024287582129,
            key: "SampleLocation-Raw Jce Exit-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-Raw Jce Exit-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -5.6251635495591135,
            key: "SampleLocation-Raw Jce Exit-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 19.29270453316567,
            key: "SampleLocation-Raw Jce Exit-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Raw Jce Exit",
  },
  {
    type: "SampleLocation",
    name: "Seal Tank",
    children: [
      {
        type: "Analyte",
        name: "% Sugar",
        key: "SampleLocation-Seal Tank-Analyte-% Sugar",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -4.884646290922269,
            key: "SampleLocation-Seal Tank-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 6.027206746114304,
            key: "SampleLocation-Seal Tank-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Alkalinity",
        key: "SampleLocation-Seal Tank-Analyte-Alkalinity",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Seal Tank-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Seal Tank-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "CaO",
        key: "SampleLocation-Seal Tank-Analyte-CaO",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Seal Tank-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Seal Tank-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Color",
        key: "SampleLocation-Seal Tank-Analyte-Color",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -1820.1374380407683,
            key: "SampleLocation-Seal Tank-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 2384.9945808979114,
            key: "SampleLocation-Seal Tank-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "PH",
        key: "SampleLocation-Seal Tank-Analyte-PH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Seal Tank-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Seal Tank-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Purity",
        key: "SampleLocation-Seal Tank-Analyte-Purity",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 75.5228846222988,
            key: "SampleLocation-Seal Tank-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 104.64378204436785,
            key: "SampleLocation-Seal Tank-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "RDS",
        key: "SampleLocation-Seal Tank-Analyte-RDS",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 18.027231521714526,
            key: "SampleLocation-Seal Tank-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 107.27276847828547,
            key: "SampleLocation-Seal Tank-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Sugar Traces",
        key: "SampleLocation-Seal Tank-Analyte-Sugar Traces",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 0.517662297632554,
            key: "SampleLocation-Seal Tank-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 3.9345848933786822,
            key: "SampleLocation-Seal Tank-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-Seal Tank-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -65.83466823920851,
            key: "SampleLocation-Seal Tank-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 85.27599592282432,
            key: "SampleLocation-Seal Tank-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Seal Tank",
  },
  {
    type: "SampleLocation",
    name: "Std Liquor",
    children: [
      {
        type: "Analyte",
        name: "% Sugar",
        key: "SampleLocation-Std Liquor-Analyte-% Sugar",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 51.8750315925285,
            key: "SampleLocation-Std Liquor-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 77.36330174080484,
            key: "SampleLocation-Std Liquor-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Brix",
        key: "SampleLocation-Std Liquor-Analyte-Brix",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -20.783756775171895,
            key: "SampleLocation-Std Liquor-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 100.7537567751719,
            key: "SampleLocation-Std Liquor-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Color",
        key: "SampleLocation-Std Liquor-Analyte-Color",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 810.6561461340261,
            key: "SampleLocation-Std Liquor-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 5829.21027808856,
            key: "SampleLocation-Std Liquor-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Main Limer",
        key: "SampleLocation-Std Liquor-Analyte-Main Limer",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 8.028220211291867,
            key: "SampleLocation-Std Liquor-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 9.771779788708134,
            key: "SampleLocation-Std Liquor-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "None",
        key: "SampleLocation-Std Liquor-Analyte-None",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Std Liquor-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Std Liquor-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Purity",
        key: "SampleLocation-Std Liquor-Analyte-Purity",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -121.68041840437351,
            key: "SampleLocation-Std Liquor-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 314.59170585376626,
            key: "SampleLocation-Std Liquor-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "RDS",
        key: "SampleLocation-Std Liquor-Analyte-RDS",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -522.0516711120778,
            key: "SampleLocation-Std Liquor-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 680.5261549576254,
            key: "SampleLocation-Std Liquor-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "SO2",
        key: "SampleLocation-Std Liquor-Analyte-SO2",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Std Liquor-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Std Liquor-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Screen -100",
        key: "SampleLocation-Std Liquor-Analyte-Screen -100",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Std Liquor-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Std Liquor-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-Std Liquor-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -83.3119730577069,
            key: "SampleLocation-Std Liquor-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 106.45856914891212,
            key: "SampleLocation-Std Liquor-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Std Liquor",
  },
  {
    type: "SampleLocation",
    name: "Sug Produced",
    children: [
      {
        type: "Analyte",
        name: "% Ash",
        key: "SampleLocation-Sug Produced-Analyte-% Ash",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 0.0008662668073007402,
            key: "SampleLocation-Sug Produced-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 0.014983733192699258,
            key: "SampleLocation-Sug Produced-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "% Moisture",
        key: "SampleLocation-Sug Produced-Analyte-% Moisture",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -10.993627687122864,
            key: "SampleLocation-Sug Produced-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 16.180770544265723,
            key: "SampleLocation-Sug Produced-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Black Sediment",
        key: "SampleLocation-Sug Produced-Analyte-Black Sediment",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 2.0,
            key: "SampleLocation-Sug Produced-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 2.0,
            key: "SampleLocation-Sug Produced-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "CV",
        key: "SampleLocation-Sug Produced-Analyte-CV",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 21.50404307381473,
            key: "SampleLocation-Sug Produced-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 36.273734703963044,
            key: "SampleLocation-Sug Produced-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Color",
        key: "SampleLocation-Sug Produced-Analyte-Color",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -410.5493606519565,
            key: "SampleLocation-Sug Produced-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 557.2160273186231,
            key: "SampleLocation-Sug Produced-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Lime Salts",
        key: "SampleLocation-Sug Produced-Analyte-Lime Salts",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Sug Produced-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Sug Produced-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "MA",
        key: "SampleLocation-Sug Produced-Analyte-MA",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 0.013897808995257683,
            key: "SampleLocation-Sug Produced-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 0.018068857671408988,
            key: "SampleLocation-Sug Produced-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "SO2",
        key: "SampleLocation-Sug Produced-Analyte-SO2",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 1.0261025882100796,
            key: "SampleLocation-Sug Produced-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 5.72639741178992,
            key: "SampleLocation-Sug Produced-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Screen +35",
        key: "SampleLocation-Sug Produced-Analyte-Screen +35",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 29.12610619075204,
            key: "SampleLocation-Sug Produced-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 62.083893809247954,
            key: "SampleLocation-Sug Produced-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Screen +65",
        key: "SampleLocation-Sug Produced-Analyte-Screen +65",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 89.26260112449438,
            key: "SampleLocation-Sug Produced-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 99.92406554217229,
            key: "SampleLocation-Sug Produced-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Screen -100",
        key: "SampleLocation-Sug Produced-Analyte-Screen -100",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -2.284669112017667,
            key: "SampleLocation-Sug Produced-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 5.638786759076491,
            key: "SampleLocation-Sug Produced-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "V Speck",
        key: "SampleLocation-Sug Produced-Analyte-V Speck",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -0.9855589705353072,
            key: "SampleLocation-Sug Produced-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 2.0475589705353068,
            key: "SampleLocation-Sug Produced-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "White Sediment",
        key: "SampleLocation-Sug Produced-Analyte-White Sediment",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 1.0,
            key: "SampleLocation-Sug Produced-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 1.0,
            key: "SampleLocation-Sug Produced-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Sug Produced",
  },
  {
    type: "SampleLocation",
    name: "Thick Juice",
    children: [
      {
        type: "Analyte",
        name: "Color",
        key: "SampleLocation-Thick Juice-Analyte-Color",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Thick Juice-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Thick Juice-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Thick Juice",
  },
  {
    type: "SampleLocation",
    name: "Thin Juice",
    children: [
      {
        type: "Analyte",
        name: "Color",
        key: "SampleLocation-Thin Juice-Analyte-Color",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 720.4324482052689,
            key: "SampleLocation-Thin Juice-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 1914.2342184613976,
            key: "SampleLocation-Thin Juice-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Thin Juice",
  },
  {
    type: "SampleLocation",
    name: "Thk Jce fr Stor",
    children: [
      {
        type: "Analyte",
        name: "Color",
        key: "SampleLocation-Thk Jce fr Stor-Analyte-Color",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 2515.1373159982495,
            key: "SampleLocation-Thk Jce fr Stor-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 5571.619440758507,
            key: "SampleLocation-Thk Jce fr Stor-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Purity",
        key: "SampleLocation-Thk Jce fr Stor-Analyte-Purity",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 88.6804755135171,
            key: "SampleLocation-Thk Jce fr Stor-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 96.7146464377024,
            key: "SampleLocation-Thk Jce fr Stor-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "RDS",
        key: "SampleLocation-Thk Jce fr Stor-Analyte-RDS",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 37.42443517525912,
            key: "SampleLocation-Thk Jce fr Stor-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 87.4346124437885,
            key: "SampleLocation-Thk Jce fr Stor-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "SO2",
        key: "SampleLocation-Thk Jce fr Stor-Analyte-SO2",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 11.525636939004272,
            key: "SampleLocation-Thk Jce fr Stor-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 109.0457916324243,
            key: "SampleLocation-Thk Jce fr Stor-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Temp 'C",
        key: "SampleLocation-Thk Jce fr Stor-Analyte-Temp 'C",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 28.777842377610362,
            key: "SampleLocation-Thk Jce fr Stor-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 40.72215762238964,
            key: "SampleLocation-Thk Jce fr Stor-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-Thk Jce fr Stor-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -11.226354285353944,
            key: "SampleLocation-Thk Jce fr Stor-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 31.91798219233069,
            key: "SampleLocation-Thk Jce fr Stor-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Thk Jce fr Stor",
  },
  {
    type: "SampleLocation",
    name: "Thk Jce to Stor",
    children: [
      {
        type: "Analyte",
        name: "Color",
        key: "SampleLocation-Thk Jce to Stor-Analyte-Color",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 809.4961789786221,
            key: "SampleLocation-Thk Jce to Stor-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 6236.279940424363,
            key: "SampleLocation-Thk Jce to Stor-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "MA",
        key: "SampleLocation-Thk Jce to Stor-Analyte-MA",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Thk Jce to Stor-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Thk Jce to Stor-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Purity",
        key: "SampleLocation-Thk Jce to Stor-Analyte-Purity",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 88.71839227194806,
            key: "SampleLocation-Thk Jce to Stor-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 97.83116525017581,
            key: "SampleLocation-Thk Jce to Stor-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "RDS",
        key: "SampleLocation-Thk Jce to Stor-Analyte-RDS",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 37.95175816995274,
            key: "SampleLocation-Thk Jce to Stor-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 81.89824183004727,
            key: "SampleLocation-Thk Jce to Stor-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Temp 'C",
        key: "SampleLocation-Thk Jce to Stor-Analyte-Temp 'C",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 15.12872607073784,
            key: "SampleLocation-Thk Jce to Stor-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 62.82774451749745,
            key: "SampleLocation-Thk Jce to Stor-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-Thk Jce to Stor-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -6.467409069349131,
            key: "SampleLocation-Thk Jce to Stor-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 25.961720995954636,
            key: "SampleLocation-Thk Jce to Stor-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Thk Jce to Stor",
  },
  {
    type: "SampleLocation",
    name: "Wet S #1",
    children: [
      {
        type: "Analyte",
        name: "% Ash",
        key: "SampleLocation-Wet S #1-Analyte-% Ash",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Wet S #1-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Wet S #1-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "% Moisture",
        key: "SampleLocation-Wet S #1-Analyte-% Moisture",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 0.00902943725152286,
            key: "SampleLocation-Wet S #1-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 0.042970562748477145,
            key: "SampleLocation-Wet S #1-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "CV",
        key: "SampleLocation-Wet S #1-Analyte-CV",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Wet S #1-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Wet S #1-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Color",
        key: "SampleLocation-Wet S #1-Analyte-Color",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 4.260397874303312,
            key: "SampleLocation-Wet S #1-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 57.53854392463849,
            key: "SampleLocation-Wet S #1-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "MA",
        key: "SampleLocation-Wet S #1-Analyte-MA",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Wet S #1-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Wet S #1-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Screen +35",
        key: "SampleLocation-Wet S #1-Analyte-Screen +35",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Wet S #1-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Wet S #1-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Screen +65",
        key: "SampleLocation-Wet S #1-Analyte-Screen +65",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Wet S #1-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Wet S #1-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Screen -100",
        key: "SampleLocation-Wet S #1-Analyte-Screen -100",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-Wet S #1-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-Wet S #1-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "V Speck",
        key: "SampleLocation-Wet S #1-Analyte-V Speck",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 0.24567415185154795,
            key: "SampleLocation-Wet S #1-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 2.754325848148452,
            key: "SampleLocation-Wet S #1-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-Wet S #1",
  },
  {
    type: "SampleLocation",
    name: "White A",
    children: [
      {
        type: "Analyte",
        name: "CV",
        key: "SampleLocation-White A-Analyte-CV",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 17.282202112918654,
            key: "SampleLocation-White A-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 34.717797887081346,
            key: "SampleLocation-White A-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Color",
        key: "SampleLocation-White A-Analyte-Color",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 29.0,
            key: "SampleLocation-White A-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 29.0,
            key: "SampleLocation-White A-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "MA",
        key: "SampleLocation-White A-Analyte-MA",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 0.011589488223348474,
            key: "SampleLocation-White A-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 0.01921051177665153,
            key: "SampleLocation-White A-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Screen +35",
        key: "SampleLocation-White A-Analyte-Screen +35",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -21.83482805428046,
            key: "SampleLocation-White A-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 83.40816138761379,
            key: "SampleLocation-White A-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Screen +65",
        key: "SampleLocation-White A-Analyte-Screen +65",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -8.984115956406455,
            key: "SampleLocation-White A-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 163.46011595640647,
            key: "SampleLocation-White A-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Screen -100",
        key: "SampleLocation-White A-Analyte-Screen -100",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -0.10478835869976516,
            key: "SampleLocation-White A-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 0.5381216920330985,
            key: "SampleLocation-White A-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-White A",
  },
  {
    type: "SampleLocation",
    name: "White B",
    children: [
      {
        type: "Analyte",
        name: "CV",
        key: "SampleLocation-White B-Analyte-CV",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 23.17157287525381,
            key: "SampleLocation-White B-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 28.82842712474619,
            key: "SampleLocation-White B-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Color",
        key: "SampleLocation-White B-Analyte-Color",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 25.670291568974648,
            key: "SampleLocation-White B-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 33.32970843102535,
            key: "SampleLocation-White B-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "MA",
        key: "SampleLocation-White B-Analyte-MA",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 0.014100000000000001,
            key: "SampleLocation-White B-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 0.014499999999999999,
            key: "SampleLocation-White B-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Screen +35",
        key: "SampleLocation-White B-Analyte-Screen +35",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -19.405150503982682,
            key: "SampleLocation-White B-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 51.89265050398268,
            key: "SampleLocation-White B-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Screen +65",
        key: "SampleLocation-White B-Analyte-Screen +65",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 85.6426732091627,
            key: "SampleLocation-White B-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 98.9573267908373,
            key: "SampleLocation-White B-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Screen -100",
        key: "SampleLocation-White B-Analyte-Screen -100",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -1.378488797889961,
            key: "SampleLocation-White B-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 4.178488797889961,
            key: "SampleLocation-White B-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-White B",
  },
  {
    type: "SampleLocation",
    name: "White Pan",
    children: [
      {
        type: "Analyte",
        name: "% Ash",
        key: "SampleLocation-White Pan-Analyte-% Ash",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 0.0030499607537014433,
            key: "SampleLocation-White Pan-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 0.012150039246298556,
            key: "SampleLocation-White Pan-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "% Moisture",
        key: "SampleLocation-White Pan-Analyte-% Moisture",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -0.08966871610399846,
            key: "SampleLocation-White Pan-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 0.18191871610399846,
            key: "SampleLocation-White Pan-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "% Sugar",
        key: "SampleLocation-White Pan-Analyte-% Sugar",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 82.0918481689079,
            key: "SampleLocation-White Pan-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 86.5031518310921,
            key: "SampleLocation-White Pan-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "CV",
        key: "SampleLocation-White Pan-Analyte-CV",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -8.901254556784842,
            key: "SampleLocation-White Pan-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 76.3223071883638,
            key: "SampleLocation-White Pan-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Color",
        key: "SampleLocation-White Pan-Analyte-Color",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 7.370430939681622,
            key: "SampleLocation-White Pan-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 46.21290239365172,
            key: "SampleLocation-White Pan-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "MA",
        key: "SampleLocation-White Pan-Analyte-MA",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -2.4675290717818608,
            key: "SampleLocation-White Pan-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 2.9090872113167445,
            key: "SampleLocation-White Pan-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Main Limer",
        key: "SampleLocation-White Pan-Analyte-Main Limer",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-White Pan-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-White Pan-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "None",
        key: "SampleLocation-White Pan-Analyte-None",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -109.70562748477141,
            key: "SampleLocation-White Pan-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 229.7056274847714,
            key: "SampleLocation-White Pan-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Number",
        key: "SampleLocation-White Pan-Analyte-Number",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -687.9663423084537,
            key: "SampleLocation-White Pan-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 1705.7441200862315,
            key: "SampleLocation-White Pan-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Purity",
        key: "SampleLocation-White Pan-Analyte-Purity",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 79.87165897827151,
            key: "SampleLocation-White Pan-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 105.17211113667099,
            key: "SampleLocation-White Pan-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "RDS",
        key: "SampleLocation-White Pan-Analyte-RDS",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 81.33512022890719,
            key: "SampleLocation-White Pan-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 101.17801478253446,
            key: "SampleLocation-White Pan-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "SO2",
        key: "SampleLocation-White Pan-Analyte-SO2",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 1.5271289885397215,
            key: "SampleLocation-White Pan-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 6.1942043447936115,
            key: "SampleLocation-White Pan-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Screen +35",
        key: "SampleLocation-White Pan-Analyte-Screen +35",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 26.966686130995804,
            key: "SampleLocation-White Pan-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 67.40258216168712,
            key: "SampleLocation-White Pan-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Screen +65",
        key: "SampleLocation-White Pan-Analyte-Screen +65",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 67.0583081975702,
            key: "SampleLocation-White Pan-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 113.49983133731351,
            key: "SampleLocation-White Pan-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Screen -100",
        key: "SampleLocation-White Pan-Analyte-Screen -100",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -5.787605707949076,
            key: "SampleLocation-White Pan-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 9.757838266088612,
            key: "SampleLocation-White Pan-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "V Speck",
        key: "SampleLocation-White Pan-Analyte-V Speck",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-White Pan-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-White Pan-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-White Pan-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -4.59438411462822,
            key: "SampleLocation-White Pan-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 23.56396157941695,
            key: "SampleLocation-White Pan-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-White Pan",
  },
  {
    type: "SampleLocation",
    name: "White Pan #1",
    children: [
      {
        type: "Analyte",
        name: "% Ash",
        key: "SampleLocation-White Pan #1-Analyte-% Ash",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -0.0010317333888162326,
            key: "SampleLocation-White Pan #1-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 0.01691173338881623,
            key: "SampleLocation-White Pan #1-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "% Moisture",
        key: "SampleLocation-White Pan #1-Analyte-% Moisture",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 0.010029437251522864,
            key: "SampleLocation-White Pan #1-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 0.043970562748477146,
            key: "SampleLocation-White Pan #1-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "CV",
        key: "SampleLocation-White Pan #1-Analyte-CV",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 15.844574402812128,
            key: "SampleLocation-White Pan #1-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 37.746334688096965,
            key: "SampleLocation-White Pan #1-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Color",
        key: "SampleLocation-White Pan #1-Analyte-Color",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 6.5879957128641315,
            key: "SampleLocation-White Pan #1-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 67.44400428713587,
            key: "SampleLocation-White Pan #1-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "MA",
        key: "SampleLocation-White Pan #1-Analyte-MA",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -12.119485241826856,
            key: "SampleLocation-White Pan #1-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 14.107231908493523,
            key: "SampleLocation-White Pan #1-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "SO2",
        key: "SampleLocation-White Pan #1-Analyte-SO2",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 0.607786099749064,
            key: "SampleLocation-White Pan #1-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 2.209356757393793,
            key: "SampleLocation-White Pan #1-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Screen +35",
        key: "SampleLocation-White Pan #1-Analyte-Screen +35",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 11.91823365305222,
            key: "SampleLocation-White Pan #1-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 85.75812998331142,
            key: "SampleLocation-White Pan #1-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Screen +65",
        key: "SampleLocation-White Pan #1-Analyte-Screen +65",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 84.62148098369211,
            key: "SampleLocation-White Pan #1-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 104.35807457186344,
            key: "SampleLocation-White Pan #1-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Screen -100",
        key: "SampleLocation-White Pan #1-Analyte-Screen -100",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -0.7122338858941117,
            key: "SampleLocation-White Pan #1-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 2.2198014534616792,
            key: "SampleLocation-White Pan #1-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-White Pan #1",
  },
  {
    type: "SampleLocation",
    name: "White Sugar",
    children: [
      {
        type: "Analyte",
        name: "",
        key: "SampleLocation-White Sugar-Analyte-",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-White Sugar-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-White Sugar-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "% Ash",
        key: "SampleLocation-White Sugar-Analyte-% Ash",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -11.624834551365316,
            key: "SampleLocation-White Sugar-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 12.718012581668345,
            key: "SampleLocation-White Sugar-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "% Moisture",
        key: "SampleLocation-White Sugar-Analyte-% Moisture",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 0.01082426129553054,
            key: "SampleLocation-White Sugar-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 0.04553741117833705,
            key: "SampleLocation-White Sugar-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "+35",
        key: "SampleLocation-White Sugar-Analyte-+35",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 45.097561338236034,
            key: "SampleLocation-White Sugar-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 61.50243866176396,
            key: "SampleLocation-White Sugar-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Black Sediment",
        key: "SampleLocation-White Sugar-Analyte-Black Sediment",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -2.8530026336932384,
            key: "SampleLocation-White Sugar-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 7.729714962460362,
            key: "SampleLocation-White Sugar-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "CV",
        key: "SampleLocation-White Sugar-Analyte-CV",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 4.817735836274579,
            key: "SampleLocation-White Sugar-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 54.48400708825372,
            key: "SampleLocation-White Sugar-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "CaO",
        key: "SampleLocation-White Sugar-Analyte-CaO",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 20.257359312880716,
            key: "SampleLocation-White Sugar-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 28.742640687119284,
            key: "SampleLocation-White Sugar-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Color",
        key: "SampleLocation-White Sugar-Analyte-Color",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 7.440361936337791,
            key: "SampleLocation-White Sugar-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 55.352509350790925,
            key: "SampleLocation-White Sugar-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Conductivity",
        key: "SampleLocation-White Sugar-Analyte-Conductivity",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-White Sugar-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-White Sugar-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "MA",
        key: "SampleLocation-White Sugar-Analyte-MA",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -97.1140369782634,
            key: "SampleLocation-White Sugar-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 102.90583252861468,
            key: "SampleLocation-White Sugar-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "None",
        key: "SampleLocation-White Sugar-Analyte-None",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 0.005539571886461912,
            key: "SampleLocation-White Sugar-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 0.01426042811353809,
            key: "SampleLocation-White Sugar-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "SO2",
        key: "SampleLocation-White Sugar-Analyte-SO2",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -4.080451543263168,
            key: "SampleLocation-White Sugar-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 13.268369339873336,
            key: "SampleLocation-White Sugar-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Screen +35",
        key: "SampleLocation-White Sugar-Analyte-Screen +35",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 23.15153631861828,
            key: "SampleLocation-White Sugar-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 67.10241134918604,
            key: "SampleLocation-White Sugar-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Screen +65",
        key: "SampleLocation-White Sugar-Analyte-Screen +65",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 75.14682279121284,
            key: "SampleLocation-White Sugar-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 110.40386803551424,
            key: "SampleLocation-White Sugar-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Screen +70",
        key: "SampleLocation-White Sugar-Analyte-Screen +70",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-White Sugar-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-White Sugar-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Screen -100",
        key: "SampleLocation-White Sugar-Analyte-Screen -100",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -7.592972537989583,
            key: "SampleLocation-White Sugar-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 10.921243520648543,
            key: "SampleLocation-White Sugar-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "V Speck",
        key: "SampleLocation-White Sugar-Analyte-V Speck",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -0.47990290579927875,
            key: "SampleLocation-White Sugar-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 0.8765182904146633,
            key: "SampleLocation-White Sugar-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "White Sediment",
        key: "SampleLocation-White Sugar-Analyte-White Sediment",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -5.143644265845885,
            key: "SampleLocation-White Sugar-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 9.032533154734773,
            key: "SampleLocation-White Sugar-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-White Sugar",
  },
  {
    type: "SampleLocation",
    name: "White Sugar #1",
    children: [
      {
        type: "Analyte",
        name: "% Ash",
        key: "SampleLocation-White Sugar #1-Analyte-% Ash",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 0.001462886067261972,
            key: "SampleLocation-White Sugar #1-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 0.018916534222593102,
            key: "SampleLocation-White Sugar #1-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "% Moisture",
        key: "SampleLocation-White Sugar #1-Analyte-% Moisture",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 0.014146688818801422,
            key: "SampleLocation-White Sugar #1-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 0.04158058390847131,
            key: "SampleLocation-White Sugar #1-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "CV",
        key: "SampleLocation-White Sugar #1-Analyte-CV",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 20.563943525944012,
            key: "SampleLocation-White Sugar #1-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 38.551910132592575,
            key: "SampleLocation-White Sugar #1-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Color",
        key: "SampleLocation-White Sugar #1-Analyte-Color",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 7.520433337563901,
            key: "SampleLocation-White Sugar #1-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 59.296709519578954,
            key: "SampleLocation-White Sugar #1-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Lime Salts",
        key: "SampleLocation-White Sugar #1-Analyte-Lime Salts",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-White Sugar #1-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-White Sugar #1-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "MA",
        key: "SampleLocation-White Sugar #1-Analyte-MA",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -0.0005819129191898115,
            key: "SampleLocation-White Sugar #1-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 0.03312160522688212,
            key: "SampleLocation-White Sugar #1-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "SO2",
        key: "SampleLocation-White Sugar #1-Analyte-SO2",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -0.18392138263773994,
            key: "SampleLocation-White Sugar #1-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 6.8410151326377395,
            key: "SampleLocation-White Sugar #1-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Screen +35",
        key: "SampleLocation-White Sugar #1-Analyte-Screen +35",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 21.671856312296658,
            key: "SampleLocation-White Sugar #1-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 68.09517732684708,
            key: "SampleLocation-White Sugar #1-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Screen +65",
        key: "SampleLocation-White Sugar #1-Analyte-Screen +65",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: 84.05590605057681,
            key: "SampleLocation-White Sugar #1-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 103.07878530744789,
            key: "SampleLocation-White Sugar #1-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "Screen -100",
        key: "SampleLocation-White Sugar #1-Analyte-Screen -100",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -1.2076523159864543,
            key: "SampleLocation-White Sugar #1-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 4.488527736861875,
            key: "SampleLocation-White Sugar #1-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "V Speck",
        key: "SampleLocation-White Sugar #1-Analyte-V Speck",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: -0.44174050478037474,
            key: "SampleLocation-White Sugar #1-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: 2.3078119333518035,
            key: "SampleLocation-White Sugar #1-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-White Sugar #1-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-White Sugar #1-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-White Sugar #1-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-White Sugar #1",
  },
  {
    type: "SampleLocation",
    name: "White Sugar #2",
    children: [
      {
        type: "Analyte",
        name: "Sugar Color",
        key: "SampleLocation-White Sugar #2-Analyte-Sugar Color",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-White Sugar #2-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-White Sugar #2-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-White Sugar #2",
  },
  {
    type: "SampleLocation",
    name: "White Sugar pan",
    children: [
      {
        type: "Analyte",
        name: "Purity",
        key: "SampleLocation-White Sugar pan-Analyte-Purity",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-White Sugar pan-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-White Sugar pan-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "RDS",
        key: "SampleLocation-White Sugar pan-Analyte-RDS",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-White Sugar pan-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-White Sugar pan-Value-Upper Limit",
          },
        ],
      },
      {
        type: "Analyte",
        name: "pH",
        key: "SampleLocation-White Sugar pan-Analyte-pH",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-White Sugar pan-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-White Sugar pan-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-White Sugar pan",
  },
  {
    type: "SampleLocation",
    name: "White sugar",
    children: [
      {
        type: "Analyte",
        name: "Screen +35",
        key: "SampleLocation-White sugar-Analyte-Screen +35",
        children: [
          {
            type: "Value",
            name: "Lower Limit",
            value: NaN,
            key: "SampleLocation-White sugar-Value-Lower Limit",
          },
          {
            type: "Value",
            name: "Upper Limit",
            value: NaN,
            key: "SampleLocation-White sugar-Value-Upper Limit",
          },
        ],
      },
    ],
    key: "SampleLocation-White sugar",
  },
];

export default SampleLoc2Analyte;
