import TextField from "@mui/material/TextField";
import { FieldValues, Path, UseFormRegister } from "react-hook-form";

type FormTextFieldProps<T extends FieldValues> = {
  variant: "standard" | "filled" | "outlined";
  type: "number" | "text" | "required" | "disabled";
  name: Path<T>;
  label: string;
  multiline?: boolean;
  defaultValue?: string;
  required?: boolean;
  readOnly?: boolean | undefined;
  error?: boolean;
  helperText?: string;
  register: UseFormRegister<T>;
};

const FormTextField = <T extends FieldValues, >(
  {
    variant,
    type,
    name,
    label,
    defaultValue,
    required,
    multiline,
    error,
    helperText,
    register,
  }: FormTextFieldProps<T>) => {
  return (
    <TextField
      id={`${variant}-${type}`}
      label={`${label}`}
      variant={variant}
      defaultValue={defaultValue}
      required={required}
      multiline={multiline}
      style={multiline ? { width: "100%" } : {}}
      error={error}
      helperText={helperText}
      rows={multiline ? 4 : 1}
      {...register(name, {
        valueAsNumber: type === "number",
      })}
    />
  );
};

export default FormTextField;
