import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DowntimeList from "../../components/Downtime/DowntimeList";
import Typography from "@mui/material/Typography";

const ListDowntimesPage = () => {
  return (
    <DashboardLayout>
      <Typography variant={"h4"}>List Downtimes</Typography>
      <DowntimeList />
    </DashboardLayout>
  );
};

export default ListDowntimesPage;