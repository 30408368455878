// COPYRIGHT VESL, LLC 2023
// CONFIDENTIAL DO NOT DISTRIBUTE WITHOUT EXPRESS PERMISSION OF VESL OWNERSHIP
/* ____________________________________________________________________________________________________
COPYRIGHT VESL ENGINEERING 2023
CONFIDENTIAL DO NOT DISTRIBUTE WITHOUT EXPRESS PERMISSION OF VESL OWNERSHIP
The purpose of this component is to render the filters as well as the graphs desired on the base UI.
Use of react components to render the pieces correctly
Use of D3 to create all plots
Use of Material UI and Bootstrap for basic Styling of objects.
_______________________________________________________________________________________________________*/

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import sampleLocationOptions from "../SampleLocations.js";
import sampleLocationCommonOptions from "../SampleLocationsCommon.js";
import sampleLocationsToAnalyteHierarchy from "../SampleLocationToAnalyteHierarchy.js";
import analyteCommonOptions from "../AnalytesCommon.js";
import axios from "axios";

const LabEntry = ({
  initialProcessLocation,
  initialUnitOp,
  initialSampleLocation,
  initialAnalyte,
  initialStartTime,
  initialEndTime,
  countKey,
}) => {
  const [inputData, setInputData] = useState([0]);
  const [addSampleLocation, setAddSampleLocation] = useState(initialSampleLocation);
  const [addAnalyte, setAddAnalyte] = useState(initialAnalyte);
  const [addCollectDT, setAddCollectDT] = useState(new Date());
  const [addSampleTestDT, setAddSampleTestDT] = useState(new Date());
  const [addUOM, setAddUOM] = useState("Units");
  const [addResultValue, setAddResultValue] = useState();
  const [addUpperLimit, setAddUpperLimit] = useState(100);
  const [addLowerLimit, setAddLowerLimit] = useState(0);
  const [addCommonSampleLocation, setAddCommonSampleLocation] = useState(initialSampleLocation);
  const [addCommonAnalyte, setAddCommonAnalyte] = useState(initialAnalyte);
  const [addCommonResultValue, setAddCommonResultValue] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  // Helper function to get analytes based on selected sample location
  const getAnalyteOptions = (sampleLocation) => {
    const location = sampleLocationsToAnalyteHierarchy.find((loc) => loc.name === sampleLocation);
    return location ? location.children.map((child) => child.name) : [];
  };

  const handleRowAdd = async () => {
    setAddUOM("Units");
    var row2Add = [
      {
        SampleLocation: addSampleLocation,
        Analyte: addAnalyte,
        CollectDT: addCollectDT,
        SampleTestDT: addSampleTestDT,
        Uom: addUOM,
        ResultValue: addResultValue,
        UpperLimit: 100,
        LowerLimit: 0,
      },
    ];
    try {
      const response = await axios.put(`${apiUrl}/Samples`, row2Add, {
        timeout: 200000,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleEditRow = () => {
    setIsEditing(true);
  };

  const handleAddCommonRow = async () => {
    setAddUOM("Units");
    var row2Add = [
      {
        SampleLocation: addCommonSampleLocation,
        Analyte: addCommonAnalyte,
        CollectDT: new Date(Date.now() - 6 * 60 * 60 * 1000),
        SampleTestDT: new Date(Date.now() - 6 * 60 * 60 * 1000),
        Uom: addUOM,
        ResultValue: addCommonResultValue,
        UpperLimit: 0,
        LowerLimit: 0,
      },
    ];
    try {
      const response = await axios.put(`${apiUrl}/Samples`, row2Add, {
        timeout: 200000,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      <div>{!isEditing && <Button onClick={handleEditRow}>Edit</Button>}</div>
      <h1 className="filter-title">Use the Following Boxes to Add Lab Data to Virtual Lab</h1>

      {/* Quick Entry for Common Sample Locations and Analytes */}
      <h2 className="filter-title">Common Sample Locations and Analytes</h2>
      <div className="selectors-box">
        <div className="dropdown-box">
          <label>Sample Location:</label>
          <select
            value={addCommonSampleLocation}
            onChange={(e) => setAddCommonSampleLocation(e.target.value)}
          >
            {sampleLocationCommonOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className="dropdown-box">
          <label>Analyte:</label>
          <select value={addCommonAnalyte} onChange={(e) => setAddCommonAnalyte(e.target.value)}>
            {getAnalyteOptions(addCommonSampleLocation).map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className="input-box">
          <label>Value</label>
          <input
            type="text"
            value={addCommonResultValue}
            onChange={(e) => setAddCommonResultValue(e.target.value)}
          />
        </div>
      </div>
      <div className="parent-container">
        <button className="submit-button" onClick={handleAddCommonRow}>
          Add Lab Data Entry
        </button>
      </div>

      {/* Full Entry for All Sample Locations and Analytes */}
      <h2 className="filter-title">Data Entry: All Sample Locations and Analytes</h2>
      <div className="selectors-box">
        <div className="dropdown-box">
          <label>Sample Location:</label>
          <select
            value={addSampleLocation}
            onChange={(e) => {
              setAddSampleLocation(e.target.value);
              setAddAnalyte(""); // Reset analyte when sample location changes
            }}
          >
            {sampleLocationOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className="dropdown-box">
          <label>Analyte:</label>
          <select value={addAnalyte} onChange={(e) => setAddAnalyte(e.target.value)}>
            {getAnalyteOptions(addSampleLocation).map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="selectors-box">
        <div className="input-box">
          <label>Collection Time:</label>
          <input
            type="datetime-local"
            value={addCollectDT}
            onChange={(e) => setAddCollectDT(e.target.value)}
          />
        </div>
        <div className="input-box">
          <label>Sample Test Time</label>
          <input
            type="datetime-local"
            value={addSampleTestDT}
            onChange={(e) => setAddSampleTestDT(e.target.value)}
          />
        </div>
        <div className="input-box">
          <label>Value</label>
          <input
            type="text"
            value={addResultValue}
            onChange={(e) => setAddResultValue(e.target.value)}
          />
        </div>
      </div>
      <div className="selectors-box">
        <div className="input-box">
          <label>Upper Limit</label>
          <input
            type="text"
            value={addUpperLimit}
            onChange={(e) => setAddUpperLimit(e.target.value)}
          />
        </div>
        <div className="input-box">
          <label>Lower Limit</label>
          <input
            type="text"
            value={addLowerLimit}
            onChange={(e) => setAddLowerLimit(e.target.value)}
          />
        </div>
      </div>
      <div className="parent-container">
        <button className="submit-button" onClick={handleRowAdd}>
          Add Lab Data Entry
        </button>
      </div>
    </>
  );
};

LabEntry.propTypes = {
  initialUnitOp: PropTypes.any,
  initialProcessLocation: PropTypes.any,
  initialSampleLocation: PropTypes.any,
  initialAnalyte: PropTypes.any,
  initialStartTime: PropTypes.any, // Changed to string since input type is datetime-local
  initialEndTime: PropTypes.any, // Changed to string since input type is datetime-local
  sourceData: PropTypes.array,
  countKey: PropTypes.number,
};

export default LabEntry;
