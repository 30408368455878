import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export async function post<Type>(endpoint: string, data: Type): Promise<Response | Error> {
  try {
    return await axios.post(`${apiUrl}/${endpoint}`, data, {
      timeout: 200000,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (error) {
    console.error("HTTP post error:", error);
    return error as Error;
  }
}

export async function get<Type>(
  endpoint: string,
  data?: string[][] | Record<string, string> | string
): Promise<Type | Error> {
  try {
    const query = data ? new URLSearchParams(data).toString() : "";
    const response = await fetch(`${apiUrl}/${endpoint}?${query}`, {
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });

    return (await response.json()) as Type;
  } catch (error) {
    console.error("HTTP get error:", error);
    return error as Error;
  }
}

class JsonStreamDecoder {
  /** item starts and ends at level 0 */
  private level = 0;

  /** when an item is split in two */
  private partialItem = "";

  private decoder = new TextDecoder();

  public decodeChunk<T>(value: Uint8Array, decodedItemCallback: (item: T) => void): void {
    const chunk = this.decoder.decode(value);
    let itemStart = 0;

    for (let i = 0; i < chunk.length; i++) {
      if (chunk[i] === JTOKEN_START_OBJECT) {
        if (this.level === 0) {
          itemStart = i;
        }
        this.level++;
      }
      if (chunk[i] === JTOKEN_END_OBJECT) {
        this.level--;
        if (this.level === 0) {
          let item = chunk.substring(itemStart, i + 1);
          if (this.partialItem) {
            item = this.partialItem + item;
            this.partialItem = "";
          }
          const parsedItem = JSON.parse(item);
          decodedItemCallback(parsedItem);
        }
      }
    }
    if (this.level !== 0) {
      this.partialItem = chunk.substring(itemStart);
    }
  }
}

const JTOKEN_START_OBJECT = "{";
const JTOKEN_END_OBJECT = "}";

export async function getStream<Type>(
  endpoint: string,
  onData: (data: Type) => void,
  data?: string[][] | Record<string, string> | string
): Promise<void | Error> {
  const query = data ? new URLSearchParams(data).toString() : "";
  const response = await fetch(`${apiUrl}/${endpoint}?${query}`, {
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });

  if (!response.ok) {
    return new Error(`HTTP error: ${response.status}`);
  }

  if (!response.body) {
    return new Error("Response body is null");
  }

  const reader = response.body.getReader();
  const decoder = new JsonStreamDecoder();
  let done, value;
  while (!done) {
    ({ done, value } = await reader.read());
    if (!value) continue;
    decoder.decodeChunk<Type>(value, onData);
  }
}
