import React, { useEffect, useRef, useState, useCallback } from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";
import { Button } from "@mui/material";
import sampleLocationOptions from "./SampleLocations.js";
import analyteOptions from "./Analytes.js";

const CreatePlot = React.memo(({ passedData, indVar, depVar }) => {
  const [isLinePlot, setIsLinePlot] = useState(false);
  const [legendVisible, setLegendVisible] = useState(false);
  const [seeAnalyte, setSeeAnalyte] = useState(false);
  const [scaling, setScaling] = useState(true);
  const scatterRef = useRef(null);
  const legendRef = useRef(null);

  useEffect(() => {
    renderPlot();
    window.addEventListener("resize", renderPlot);
    return () => window.removeEventListener("resize", renderPlot);
  }, [passedData, indVar, depVar, isLinePlot, legendVisible, seeAnalyte, scaling]);

  const renderPlot = useCallback(() => {
    if (!passedData || passedData.length === 0) return;

    const container = scatterRef.current.parentElement;
    const containerWidth = container.clientWidth;
    const containerHeight = container.clientHeight;

    const margin = { top: 50, right: legendVisible ? 150 : 50, bottom: 90, left: 100 };
    const width = containerWidth - margin.left - margin.right;
    const height = containerHeight - margin.top - margin.bottom;

    d3.select(scatterRef.current).attr("width", containerWidth).attr("height", containerHeight);
    d3.select(scatterRef.current).selectAll("*").remove();

    const xScale = d3.scaleTime().range([0, width]);
    const yScale = d3.scaleLinear().range([height, 0]);

    const xAxisData = passedData.map((d) => new Date(d[indVar]));
    const yAxisData = passedData.map((d) => d[depVar]);

    const xExtent = d3.extent(xAxisData);
    const yExtent = d3.extent(yAxisData);

    xScale.domain(xExtent).nice();
    yScale.domain(scaling ? [0, 100] : yExtent).nice();

    const colorScale = d3
      .scaleOrdinal(d3.schemeCategory10)
      .domain(seeAnalyte ? analyteOptions : sampleLocationOptions);

    const svg = d3
      .select(scatterRef.current)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    if (isLinePlot) {
      const line = d3
        .line()
        .defined((d) => !isNaN(d[depVar]))
        .x((d) => xScale(new Date(d[indVar])))
        .y((d) => yScale(d[depVar]));

      const groupedData = Array.from(d3.group(passedData, (d) => d.TagName));

      groupedData.forEach(([key, values]) => {
        values.sort((a, b) => new Date(a[indVar]) - new Date(b[indVar]));

        const uniqueValues = values.filter(
          (value, index, self) =>
            index ===
            self.findIndex((v) => v[indVar] === value[indVar] && v[depVar] === value[depVar])
        );

        svg
          .append("path")
          .attr("fill", "none")
          .attr("d", line(uniqueValues))
          .style("stroke", colorScale(key));
      });
    } else {
      svg
        .selectAll("circle")
        .data(passedData)
        .enter()
        .append("circle")
        .attr("cx", (d) => xScale(new Date(d[indVar])))
        .attr("cy", (d) => yScale(d[depVar]))
        .attr("r", 5)
        .style("fill", (d) => colorScale(seeAnalyte ? d.analyte : d.sampleLocation))
        .on("mouseover", (event, d) => {
          const tooltip = svg.append("g").attr("class", "tooltip").style("display", "none");

          tooltip
            .append("rect")
            .attr("width", 250)
            .attr("height", 100)
            .attr("fill", "white")
            .attr("stroke", "black")
            .attr("stroke-width", 1)
            .attr("rx", 5)
            .attr("ry", 5);

          const options = {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            hour: "numeric",
            minute: "2-digit",
            hour12: true,
          };
          const formattedDate = new Date(d[indVar]).toLocaleString("en-US", options);

          tooltip.append("text").attr("x", 10).attr("y", 20).text(`DateTime: ${formattedDate}`);
          tooltip.append("text").attr("x", 10).attr("y", 40).text(`TagName: ${d.tagName}`);
          tooltip.append("text").attr("x", 10).attr("y", 60).text(`Value: ${d[depVar]}`);

          tooltip.attr(
            "transform",
            `translate(${xScale(new Date(d[indVar])) - 95},${yScale(d[depVar]) - 90})`
          );

          tooltip.style("display", "block");
        })
        .on("mouseout", () => {
          svg.selectAll(".tooltip").style("display", "none");
        });
    }

    svg
      .append("g")
      .attr("class", "x-axis")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(xScale));

    svg.append("g").attr("class", "y-axis").call(d3.axisLeft(yScale));

    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", height + margin.top / 2)
      .attr("text-anchor", "middle")
      .text(indVar);

    svg
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("x", -height / 2)
      .attr("y", -margin.left)
      .attr("dy", "1em")
      .attr("text-anchor", "middle")
      .text(depVar);

    renderLegend(svg, colorScale, width, height, margin);
  }, [passedData, indVar, depVar, isLinePlot, legendVisible, seeAnalyte, scaling]);

  const renderLegend = useCallback(
    (svg, colorScale, width, height, margin) => {
      d3.select(legendRef.current).selectAll("*").remove();

      if (legendVisible) {
        const legendContainer = d3
          .select(legendRef.current)
          .append("div")
          .attr("class", "legend-container");

        const legend = legendContainer
          .append("svg")
          .attr("width", 200)
          .attr("height", colorScale.domain().length * 20);

        const legendEntries = legend
          .selectAll(".legend-entry")
          .data(colorScale.domain())
          .enter()
          .append("g")
          .attr("class", "legend-entry")
          .attr("transform", (d, i) => `translate(0, ${i * 20})`);

        legendEntries.append("rect").attr("width", 18).attr("height", 18).style("fill", colorScale);

        legendEntries
          .append("text")
          .attr("x", 24)
          .attr("y", 9)
          .attr("dy", ".35em")
          .text((d) => d);
      }
    },
    [legendVisible]
  );

  return (
    <div>
      <svg ref={scatterRef}></svg>
      {/* <Button onClick={() => setIsLinePlot((prev) => !prev)}>
        {isLinePlot ? "Show Scatter Plot" : "Show Line Plot"}
      </Button> */}
      <Button onClick={() => setLegendVisible((prev) => !prev)}>
        {legendVisible ? "Hide Legend" : "Show Legend"}
      </Button>
      {/* <Button onClick={() => setSeeAnalyte((prev) => !prev)}>
        {seeAnalyte ? "Toggle By Sample Location" : "Toggle by Analyte"}
      </Button> */}
      <Button onClick={() => setScaling((prev) => !prev)}>Toggle Scaling</Button>
      <div ref={legendRef} className="legend"></div>
    </div>
  );
});

CreatePlot.propTypes = {
  passedData: PropTypes.arrayOf(
    PropTypes.shape({
      DateTime: PropTypes.string,
      tagName: PropTypes.string,
      Value: PropTypes.number,
      KeyID: PropTypes.number,
      FacilityID: PropTypes.number,
      EquipmentID: PropTypes.number,
      EquipmentName: PropTypes.string,
      id: PropTypes.number,
      sampleLocation: PropTypes.string,
      analyte: PropTypes.string,
      collectDT: PropTypes.string,
      sampleTestDT: PropTypes.string,
      uom: PropTypes.string,
      resultValue: PropTypes.number,
      upperLimit: PropTypes.number,
      lowerLimit: PropTypes.number,
      dateTime: PropTypes.string,
      unknownField: PropTypes.string,
      tagSize: PropTypes.number,
      tagName0: PropTypes.string,
      tagName1: PropTypes.string,
      tagName2: PropTypes.string,
      tagName3: PropTypes.string,
      tagName4: PropTypes.string,
    })
  ).isRequired,
  indVar: PropTypes.string.isRequired,
  depVar: PropTypes.string.isRequired,
};

export default CreatePlot;
