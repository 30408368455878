import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useDashboardData } from "../../context/dashboard";
import { Referenced } from "../../model/tags";
import { useEffect, useMemo, useState } from "react";
import moment from "moment";

// getTicks gets ticks including the start and end values
function getTicks([start, end]: [number, number], count: number): number[] {
  const interval = (end - start) / (count - 1);
  const ticks = Array.from({ length: count }, (_, i) => start + i * interval);
  return [...ticks, end];
}

export function Plot({ reference, displayName }: Referenced) {
  const { data, timeFrameMin } = useDashboardData(reference);
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const { range, ticks } = useMemo(() => {
    const range: [number, number] = [
      currentTime.getTime() - timeFrameMin * 60 * 1000,
      currentTime.getTime(),
    ];
    return {
      range,
      ticks: getTicks(range, 5),
    };
  }, [timeFrameMin, currentTime]);
  return (
    <div className={"h-96 flex"}>
      <ResponsiveContainer>
        <LineChart data={data} margin={{ top: 2, right: 2, left: 0, bottom: 2 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey={"timeUnix"}
            domain={range}
            type={"number"}
            tickFormatter={(timestamp) => moment(timestamp).format("HH:mm:ss")}
            tick={{ fontSize: 12 }}
            ticks={ticks}
          />
          <YAxis dataKey={"value"} tick={{ fontSize: 12 }} domain={["auto", "auto"]} />
          <Tooltip
            labelFormatter={(label) => new Date(label).toLocaleTimeString()}
            formatter={(value) => [(value as number).toFixed(2), displayName]}
            contentStyle={{ fontSize: "12px" }}
          />
          <Line
            type={"monotone"}
            dataKey={"value"}
            stroke={"#0b0cfc"}
            dot={false}
            isAnimationActive={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
