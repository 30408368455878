import operatingRanges from "../data/operatingRanges.json";

const percentWindow = 0.1;

const ranges = operatingRanges as SampleLocationWindow;

interface SampleLocationWindow {
  [sampleLocation: string]: AnalyteOperatingWindow;
}

interface AnalyteOperatingWindow {
  [analyte: string]: OperatingWindow | number;
}

interface Sample {
  sampleLocation: string;
  analyte: string;
}

export interface OperatingWindow {
  min: number;
  max: number;
}

export function getOperatingWindow({
  sampleLocation,
  analyte,
}: Sample): OperatingWindow | undefined {
  const window = ranges[sampleLocation]?.[analyte];
  if (typeof window === "number") {
    return {
      min: window - window * percentWindow,
      max: window + window * percentWindow,
    };
  }
  return window;
}

const warningStyle = "bg-yellow-300 text-yellow-800";
const dangerStyle = "bg-red-300 text-red-800";

export function getStyleForValue(value: number, window?: OperatingWindow) {
  if (!window) {
    return "";
  }
  const { min, max } = window;
  const range = max - min;
  const warningThreshold = range * 0.1;
  const dangerThreshold = range * 0.2;

  if (value < min - dangerThreshold || value > max + dangerThreshold) {
    return dangerStyle;
  } else if (value < min - warningThreshold || value > max + warningThreshold) {
    return warningStyle;
  }
  return "";
}
