import FeedbackButton from "../../layouts/dashboards/LabDash/FeedBackForm";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function Footer() {
  return (
    <Box
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={1.5}
      py={2}
      mt="auto"
    >
      <Box>
        <FeedbackButton />
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        color="text"
        style={{ whiteSpace: "nowrap" }}
      >
        <Typography variant={"h6"}>
          &copy; {new Date().getFullYear()}, Copyright VESL,LLC Virtual Factory
        </Typography>
      </Box>
      <Box>
        <Typography variant={"h6"}>Powered by VESL Technologies</Typography>
      </Box>
    </Box>
  );
}

export default Footer;