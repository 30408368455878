import Box from "@mui/material/Box";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { FormEventHandler } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import Typography from "@mui/material/Typography";

type FormProps = {
  onSubmit: FormEventHandler;
  children: React.ReactNode;
  loading?: boolean;
  hasSubmitted?: boolean;
  timeOfSubmission?: Date | null;
};

const Form = (
  {
    onSubmit,
    children,
    loading,
    hasSubmitted,
    timeOfSubmission,
  }: FormProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        component={"form"}
        sx={{ "& .MuiFormControl-root": { m: 1, width: "25ch" } }}
        noValidate
        autoComplete="off"
        onSubmit={onSubmit}
      >
        {children}
        <Box>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={loading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            disabled={hasSubmitted}
          >
            Save
          </LoadingButton>
          {hasSubmitted && <Typography variant="body2">Submitted at {timeOfSubmission?.toLocaleString()}</Typography>}
        </Box>
      </Box>

    </LocalizationProvider>
  );
};

export default Form;
