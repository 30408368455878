import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import MDBox from "components/MDBox";
import "./Page.css";
import sampleLocationOptions from "./SampleLocationsNoneTop.js";
import CreateTable from "./CreateTableAbstract.js";
import CreatePlot from "./CreatePlotAbstract.js";
import UnitOp2SampleLoc from "./UnitOp2SampleLoc.js";
import ProcessLoc2UnitOp from "./ProcessLoc2UnitOp";
import SampleLoc2Analyte from "./SampleLocationToAnalyteHierarchy.js"; // Importing SampleLoc2Analyte
import filterData from "./filterJSON"; // Assuming this is where the filter logic resides

const SelectorsTablePlotAbstract = ({
  initialUnitOp,
  initialProcessLocation,
  initialSampleLocation,
  initialAnalyte,
  initialStartTime,
  initialEndTime,
}) => {
  const [processLocation, setProcessLocation] = useState(initialProcessLocation || "");
  const [unitOp, setUnitOp] = useState(initialUnitOp || "");
  const [sampleLocation, setSampleLocation] = useState(initialSampleLocation || "");
  const [inputHeader, setInputHeader] = useState([]);
  const [analyte, setAnalyte] = useState(initialAnalyte || "");
  const [startTime, setStartTime] = useState(initialStartTime || "");
  const [endTime, setEndTime] = useState(initialEndTime || "");
  const [filteredData, setFilteredData] = useState([]);
  const [filterValues, setFilterValues] = useState({
    analyte: "None",
    sampleLocation: "None",
    endTime: null,
    startTime: null,
  });
  const [analyteOptions, setAnalyteOptions] = useState([]);

  useEffect(() => {
    setUnitOp(initialUnitOp || "");
    setProcessLocation(initialProcessLocation || "");
    setSampleLocation(initialSampleLocation || "");
    setAnalyte(initialAnalyte || "");
    setStartTime(initialStartTime || "");
    setEndTime(initialEndTime || "");

    // Optionally trigger a new data fetch when these initial props change
    handleSelections();
  }, [
    initialUnitOp,
    initialProcessLocation,
    initialSampleLocation,
    initialAnalyte,
    initialStartTime,
    initialEndTime,
  ]);

  useEffect(() => {
    const initializeAnalyteOptions = () => {
      if (sampleLocation === "None" || !sampleLocation) {
        // If no sample location is selected or it's "None", show all analytes
        const allAnalytes = SampleLoc2Analyte.flatMap((location) =>
          location.children.map((child) => child.name)
        );
        setAnalyteOptions(allAnalytes);
      } else {
        // Find the analytes based on the selected sample location
        const selectedLocation = SampleLoc2Analyte.find(
          (location) => location.name === sampleLocation
        );
        if (selectedLocation) {
          setAnalyteOptions(selectedLocation.children.map((child) => child.name));
        } else {
          setAnalyteOptions([]);
        }
      }
    };

    initializeAnalyteOptions();
  }, [sampleLocation]);

  // Additional useEffect to handle the first load case
  useEffect(() => {
    if (sampleLocation === "None") {
      // Trigger the logic to ensure analytes for "None" sample location are set
      const allAnalytes = SampleLoc2Analyte.flatMap((location) =>
        location.children.map((child) => child.name)
      );
      setAnalyteOptions(allAnalytes);
    }
  }, []); // Empty dependency array to run only on mount

  const handleStartTimeChange = useCallback((e) => {
    setStartTime(e.target.value);
  }, []);

  const handleEndTimeChange = useCallback((e) => {
    setEndTime(e.target.value);
  }, []);

  const handleSelections = useCallback(() => {
    setFilterValues({
      analyte,
      sampleLocation,
      endTime,
      startTime,
    });
  }, [analyte, sampleLocation, endTime, startTime]);

  const clearSelections = () => {
    setFilterValues({
      analyte: initialAnalyte || "None",
      sampleLocation: initialSampleLocation || "None",
      endTime: initialEndTime || null,
      startTime: initialStartTime || null,
    });

    // Additionally, reset the individual state values if needed
    setAnalyte(initialAnalyte || "None");
    setSampleLocation(initialSampleLocation || "None");
    setEndTime(initialEndTime || null);
    setStartTime(initialStartTime || null);
  };

  const getFilteredData = async () => {
    let locString = "";
    if (initialUnitOp !== null && initialUnitOp !== "None") {
      locString = getSampleLocations(initialUnitOp);
    } else if (initialProcessLocation !== null && initialProcessLocation !== "None") {
      locString = getSampleLocations(initialProcessLocation);
    } else {
      // Handle the case where both are None
      locString = getSampleLocations("all"); // Fetch all or default locations
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/Samples/ByLocationArr?sampleLocations=${locString}`,
        {
          timeout: 200000,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      console.log("API Response:", response.data);

      const dataArr = filterData(response.data, analyte, sampleLocation, startTime, endTime, false);
      dataArr.sort((a, b) => b.ID - a.ID).reverse();

      setFilteredData(dataArr);
      setInputHeader(Object.keys(dataArr[0] || {}));
      console.log("API ResponseNow:", response.data);
      console.log("Filtered Data:", dataArr);
      console.log("Filter Values:", filterValues);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Helper function to get analytes based on selected sample location
  const getAnalyteOptions = (sampleLocation) => {
    const location = SampleLoc2Analyte.find((loc) => loc.name === sampleLocation);
    return location ? location.children.map((child) => child.name) : [];
  };

  function getSampleLocations(input) {
    if (input === null || input === "None") {
      // Return all possible sample locations or a specific fallback value
      return "all"; // Update this with the appropriate value or logic
    }
    if (UnitOp2SampleLoc[input]) {
      const sampleLocations = UnitOp2SampleLoc[input].sampleLocations;
      const modifiedLocations = sampleLocations.map((location) =>
        location.replace(/ /g, "%20").replace(/#/g, "%23")
      );
      return modifiedLocations.join(",");
    } else if (ProcessLoc2UnitOp[input]) {
      const unitOps = ProcessLoc2UnitOp[input].unitOps;
      const sampleLocations = [];

      for (const unitOp of unitOps) {
        if (UnitOp2SampleLoc[unitOp]) {
          sampleLocations.push(...UnitOp2SampleLoc[unitOp].sampleLocations);
        }
      }

      const modifiedSamples = sampleLocations.map((location) =>
        location.replace(/ /g, "%20").replace(/#/g, "%23")
      );
      return modifiedSamples.join(",");
    } else {
      return "Invalid input - Unit Op or Process Area not found";
    }
  }

  useEffect(() => {
    getFilteredData();
  }, [filterValues]);

  return (
    <>
      <MDBox className="plot-box">
        {filteredData.length > 0 && (
          <CreatePlot passedData={filteredData} indVar={"collectDT"} depVar={"resultValue"} />
        )}
      </MDBox>
      <div>
        <h2 className="filter-title">FILTER DATA</h2>
        <h2 className="filter-title">
          Select the sample location, analyte and <br /> datetime to filter the virtual lab database
        </h2>
      </div>
      <div className="selectors-box">
        <div className="dropdown-box">
          <label>Sample Location:</label>
          <select value={sampleLocation} onChange={(e) => setSampleLocation(e.target.value)}>
            {sampleLocationOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>{" "}
      </div>
      <div className="selectors-box">
        <div className="dropdown-box">
          <label>Analyte:</label>
          <select value={analyte} onChange={(e) => setAnalyte(e.target.value)}>
            {getAnalyteOptions(sampleLocation).map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="selectors-box">
        <div className="input-box">
          <label>Start Date Time:</label>
          <input type="datetime-local" value={startTime} onChange={handleStartTimeChange} />
        </div>
      </div>
      <div className="selectors-box">
        <div className="input-box">
          <label>End Date Time:</label>
          <input type="datetime-local" value={endTime} onChange={handleEndTimeChange} />
        </div>
      </div>
      <div className="parent-container">
        <button className="submit-button" onClick={handleSelections}>
          Apply Filters
        </button>
        <div></div>
        <button className="submit-button" onClick={clearSelections}>
          Clear Filters
        </button>
      </div>
      <br />
      <MDBox className="table-box">
        {filteredData.length > 0 && (
          <CreateTable passedData={filteredData} headers={inputHeader} type="Lab" />
        )}
      </MDBox>
    </>
  );
};

SelectorsTablePlotAbstract.propTypes = {
  initialUnitOp: PropTypes.any,
  initialProcessLocation: PropTypes.any,
  initialSampleLocation: PropTypes.any,
  initialAnalyte: PropTypes.any,
  initialStartTime: PropTypes.any,
  initialEndTime: PropTypes.any,
};

export default SelectorsTablePlotAbstract;
