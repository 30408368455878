// MSAL START
import React from "react";
import PropTypes from "prop-types";
import { MsalProvider } from "@azure/msal-react";
import { b2cPolicies, protectedResources } from "./components/MSAL/authConfig.js";
import { compareIssuingPolicy } from "./utils/claimUtils";
import "./styles/globals.css";
import { useEffect } from "react";
import { Splash } from "./Splash";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

/**
 COPYRIGHT VESL ENGINEERING 2023
 VESL VIRTUAL FACTORY
 */

const Pages = () => {
  /**
   * useMsal is hook that returns the PublicClientApplication instance,
   * an array of all accounts currently signed in and an inProgress value
   * that tells you what msal is currently doing. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
   */
  /*****************************************\
   MSAL V1.0 - SSO Features for WSC
   \*****************************************/
  const { instance } = useMsal();
  const msalInstance = new PublicClientApplication(msalConfig);
  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        event.payload.account
      ) {
        /**
         * For the purpose of setting an active account for UI update, we want to consider only the auth
         * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
         * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
         * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
         */
        if (compareIssuingPolicy(event.payload.idTokenClaims, b2cPolicies.names.editProfile)) {
          // retrieve the account from initial sing-in to the app
          const originalSignInAccount = instance
            .getAllAccounts()
            .find(
              (account) =>
                account.idTokenClaims.oid === event.payload.idTokenClaims.oid &&
                account.idTokenClaims.sub === event.payload.idTokenClaims.sub &&
                compareIssuingPolicy(account.idTokenClaims, b2cPolicies.names.signUpSignIn)
            );
          let signUpSignInFlowRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            account: originalSignInAccount,
          };
          // silently login again with the signUpSignIn policy
          instance.ssoSilent(signUpSignInFlowRequest);
        }
        /**
         * Below we are checking if the user is returning from the reset password flow.
         * If so, we will ask the user to reauthenticate with their new password.
         * If you do not want this behavior and prefer your users to stay signed in instead,
         * you can replace the code below with the same pattern used for handling the return from
         * profile edit flow
         */
        if (compareIssuingPolicy(event.payload.idTokenClaims, b2cPolicies.names.forgotPassword)) {
          let signUpSignInFlowRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            scopes: [
              ...protectedResources.apiTodoList.scopes.read,
              ...protectedResources.apiTodoList.scopes.write,
              ...protectedResources.dashApiResource.scopes.read,
              ...protectedResources.dashApiResource.scopes.write,
            ],
          };
          instance.loginRedirect(signUpSignInFlowRequest);
        }
      }
      if (event.eventType === EventType.LOGIN_FAILURE) {
        // Check for forgot password error
        // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
        if (event.error && event.error.errorMessage.includes("AADB2C90118")) {
          const resetPasswordRequest = {
            authority: b2cPolicies.authorities.forgotPassword.authority,
            scopes: [],
          };
          instance.loginRedirect(resetPasswordRequest);
        }
      }
    });
    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
    // eslint-disable-next-line
  }, [instance]);
  // Part-1 (return)
  /*
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      {getRoutes(routes)}
      <Route path="*" element={<Navigate to="/dashboards/VeslTablePlotDash_SamplesTbl" />} />
    </Routes>
  );
  */
};

function PrintEnvironmentVariables() {
  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      // Loop through all environment variables and print them
      for (let key in process.env) {
        console.log(`${key}: ${process.env[key]}`);
      }
    }
  }, []);
}

const queryClient = new QueryClient();
//APP COMPONENT
const App = ({ instance }) => {
  PrintEnvironmentVariables();
  return (
    <MsalProvider instance={instance}>
      <QueryClientProvider client={queryClient}>
        <Splash />
      </QueryClientProvider>
    </MsalProvider>
  );
};
App.propTypes = {
  instance: PropTypes.any,
};
export default App;
