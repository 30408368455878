import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import LabDashNavBar from "../../../../examples/Navbars/DashboardNavbar/index";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar/index.js";
import LabSheetEntry from "./LabEntry";
import "../Page.css";
import FeedbackForm from "../FeedBackForm.js";

/**
 * Abstract Page defines both the layout and the sub components initialized when a labdash page is rendered.
 *  - uses CreatePlot() -> generate plots
 *  - if no data is passed this will still run
 *  - When No Data Passed
        - Maps Last 3 hours
    - None is defined at the top of all 3 static files
    - the static files could be joined into a single json structure to simplify this
    - currently users can render any combination of the analytes and sample locations
    - all input parameters have a "_"+variableName
 * @param {*} param0
 * @returns
 */
const AbstractPage = () => {
  return (
    <>
      <DashboardLayout>
        <MDBox py={3}>
          <div>
            <div>
              <LabDashNavBar />
            </div>
            <br />
            <LabSheetEntry />
            <br />
            <FeedbackForm />
          </div>
        </MDBox>
      </DashboardLayout>
    </>
  );
};

export default AbstractPage;
