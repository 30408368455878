const Analytes = [
  "None",
  "% Ash",
  "% Moisture",
  "% Sugar",
  "Alkalinity",
  "Brix",
  "CaO",
  "Cold Limer",
  "Color",
  "Conductivity",
  "CV",
  "Lime Salts",
  "MA",
  "Main Limer",
  "pH",
  "Purity",
  "RDS",
  "Screen +35",
  "Screen +65",
  "Screen -100",
  "SO2",
];

export default Analytes;
