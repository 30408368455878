import Box from "@mui/material/Box";
import AddRows from "./AddRows";

export default function NoRowsOverlay({ addRows }) {
  return (
    <Box
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        zIndex: 1000,
      }}
    >
      <AddRows addRows={addRows} />
    </Box>
  );
}
